/*
File Name: FlashcardChapterListing
Author: Dhiraj Rajput
Modified On: 13/04/2022
*/
import React ,{ useEffect,useState }from "react";
import { Breadcrumb,Row,Col, Card, Button, Space, Tooltip, Table,Form,Radio } from "antd";
import { FileSearchOutlined, } from '@ant-design/icons';
import "../../../styles/login.css";
import "../../../styles/admin.css";
import { fetchAdminApi } from "../../../services/api";
import constants from "../../../constants/constants";
import { useHistory } from "react-router";
import { usePagination } from "../../../globalFunctions/PaginationContext";
const FlashcardChapterListing = (props) => {
	const history = useHistory();
	const [isFlashTextBook,setIsTextBook]=useState(props && props.history && props.history.location && props.history.location.state?props.history.location.state.flashcardsForValue:true);
	const [chapterTableData, setChapterTableData] = useState([]);
	const [count, setCount] = useState("");
	const { pagination, updatePagination, resetPagination } = usePagination();
	const chapterCurrentPage = pagination["flashcardchapter"] || 1;
	const topicCurrentPage = pagination["flashcardtopic"] || 1;
	useEffect(() => {
		fetchFlashcardData();
	}, [isFlashTextBook]);
	useEffect(()=>{
		// return () => {
		// 	if (history.action === "POP") {
		// 		history.push("/admin-dashboard");
		// 	}
		// };
	},[history]);
	const fetchFlashcardData = ()=>{
		let topicFlashList=`/flashcards-list/1`;
		let chapterFlashList=`/flashcards-list/0`;
		fetchAdminApi(isFlashTextBook?chapterFlashList:topicFlashList, "get").then((res) => {
			if (res && res.code === 200) {
				if (res && res.data) {
					setChapterTableData(res.data);
					setCount(res.data.length);
				}
			}else{
				setChapterTableData([]);
				setCount("");
			}
		});
	};
	const commonColumns = [
		{
			title: 'To Be Verified',
			key: 'tobeverified',
			dataIndex: 'tobeverified',
			align: 'center',
			className: 'td-right',
		},
		{
			title: 'Verified',
			key: 'verified',
			dataIndex: 'verified',
			align: 'center',
			className: 'td-right',
		},
		{
			title: 'Rejected',
			key: 'rejected',
			dataIndex: 'rejected',
			align: 'center',
			className: 'td-right',
		},
	];
	const handleOnclickAction = (record,type) => {
		let state;
		if(type==="chapter"){
			resetPagination("flashcardchapterlisting");
			state ={
				title: record && record.chapters,
				chapterId: record && record.chapter_id,
				type:0
			};
		}else{
			resetPagination("flashcardtopiclisting");
			state ={
				title: record && record.Topics,
				topicId: record && record.topic_id,
				type:1
			};
		}
		history.push({
			pathname : "/list-flashcard-chapters",
			state : state
		});
	};
	const flashcardChapterColumn = [
		{
			title: 'Actions',
			align:'center',
			render: (record) => {
				return (
					<Space>
						<Tooltip title={'View'}><Button onClick = {() =>{ handleOnclickAction(record,"chapter"); }} type="text" icon={<FileSearchOutlined/>}></Button></Tooltip>
						{/* <Tooltip title={'Delete'}><Button type="text" icon={<DeleteOutlined/>}></Button></Tooltip> */}
					</Space>
				);
			}
		},
		{
			title: 'Chapters',
			key: 'chapters',
			dataIndex: 'chapters',
		},
		...commonColumns
	];
	const flashcardTopicColumn = [
		{
			title: 'Actions',
			align:'center',
			render: (record) => {
				return (
					<Space>
						<Tooltip title={'View'}><Button onClick = {() =>{ handleOnclickAction(record,"topic"); }} type="text" icon={<FileSearchOutlined/>}></Button></Tooltip>
						{/* <Tooltip title={'Delete'}><Button type="text" icon={<DeleteOutlined/>}></Button></Tooltip> */}
					</Space>
				);
			}
		},
		{
			title: 'Topics',
			key: 'topics',
			dataIndex: 'Topics',
		},
		...commonColumns
	];
	const flashcardsFor = [
		{
			label: 'Textbook',
			value: true 
		},
		{
			label: 'Topics',
			value: false 
		},
	];
	const displayCountMessage = () =>{
		let countMessage = "";
		if(count !== null){
			countMessage += `${count} record(s) found.`;
			// if(isFlashTextBook === true){
			// 	countMessage += " "+"chapter(s).";
			// }
			// if (isFlashTextBook === false){
			// 	countMessage += " "+"topic(s).";
			// }
		}else{
			return null;
		}
		return countMessage;
	};
	const onChangeDisplayFlashcard = (e) => {
		if (e.target.value){
			resetPagination("flashcardchapter");
		}else{
			resetPagination("flashcardtopic");
		}
		setIsTextBook(e.target.value);
	};
	return (
		<div className="div">
			<div className="p-3" >
				<Breadcrumb>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/admin-dashboard" });
					}}>Home</a></Breadcrumb.Item>
					<Breadcrumb.Item ><a onClick = {() => {
						history.push({ pathname :"/masters-menu" });
					}}>Masters</a></Breadcrumb.Item>
					<Breadcrumb.Item>{isFlashTextBook === true?constants.FlashcardchapterTitle:"Flashcards - Topics"}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Row gutter={[24, 24]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Card size="small" title={<span className="text-uppercase">{isFlashTextBook === true?constants.FlashcardchapterTitle:"FLASHCARDS - TOPICS"}</span>} extra={
						<Form layout="horizontal">
							<Space>
								<Form.Item className="label-default mb-0" label="Display flashcards for">
									<Radio.Group value={isFlashTextBook} onChange={(e)=>{ onChangeDisplayFlashcard(e);}
									} options={flashcardsFor} size="small" optionType="button" buttonStyle="solid"/>
								</Form.Item>
							</Space>
						</Form>
					}>
						<p >{displayCountMessage()}</p>
						<Table className="admin-gap-table"
							locale={{ emptyText: 'No Records Found' }}
							dataSource={chapterTableData}
							columns={isFlashTextBook === true?flashcardChapterColumn:flashcardTopicColumn}
							pagination={{
								showSizeChanger: true,
								current: isFlashTextBook === true?chapterCurrentPage:topicCurrentPage,
								onChange: (page) => updatePagination(isFlashTextBook === true?"flashcardchapter":"flashcardtopic", page) 
							}}
						/>
					</Card>
				</Col>
			</Row> 
		</div>
	);
};
export default FlashcardChapterListing;
