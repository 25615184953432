import { createContext, useContext, useState } from "react";
const PaginationContext = createContext();
export const PaginationProvider = ({ children }) => {
	const [pagination, setPagination] = useState({});
	const updatePagination = (key, page = 1) => {
		setPagination((prev) => ({
			...prev,
			[key]: page 
		}));
	};
	const resetPagination = (key) => {
		setPagination((prev) => ({
			...prev,
			[key]: 1  // Reset to page 1 or any default value
		}));
	};
	return (
		<PaginationContext.Provider value={{
			pagination,
			updatePagination,
			resetPagination
		}}>
			{children}
		</PaginationContext.Provider>
	);
};
export const usePagination = () => useContext(PaginationContext);
