import React, { useState, useEffect } from "react";
import { Breadcrumb,Modal, Row, Col, Card,Checkbox,Typography,Tooltip, Form, Radio, Switch, Input, Select, Button, Table, Space, Divider, message ,Popover, DatePicker } from 'antd';
import Avatar from "antd/lib/avatar/avatar";
import constants from '../../../constants/constants';
import {
	CheckCircleTwoTone, CloseCircleTwoTone,
	PlusOutlined,CloseOutlined,InfoCircleOutlined
} from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import { fetchAdminApi, fetchApi } from "../../../services/api";
import '../../../styles/test.css';
import { useHistory } from "react-router";
import { getBadgeIcon,displayOtherUniversityAlaska } from "../../../globalFunctions/GlobalFunctions";
import { COOKIE, getCookie } from "../../../services/cookie";
const { TextArea } = Input;
const { Text } = Typography;
const { Option, OptGroup } = Select;
const horizontalLayout = {
	labelCol: { span: 10, },
	wrapperCol: { span: 14, },
};
const verticalLayout = {
	labelCol: { span: 24, },
	wrapperCol: { span: 24, },
};
const EditStudent = (props) => {
	const [disableSwith, setDisableSwith] = useState(false);
	const [form] = Form.useForm();
	const [listCountries, setCountries] = useState([]);
	const [listUniversities, setUniversities] = useState([]);
	const [studentId,] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.studentId : localStorage.getItem("studentId"));
	const [fromTestResult,] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.fromTestResult :false);
	const [fromDashboard,setFronDashboard] = useState();
	const [studentData, setStudentData] = useState([]);
	const [displayOccupation, setDisplayOccupation] = useState(false);
	const [displayOtherUniversity, setDisplayOtherUniversity] = useState(false);
	const [isTimerValue, setIsTimerValue] = useState(false);
	const [subscriptionData, setSubscriptionData] = useState([]);
	const [styles, setStyles] = useState(null);
	const [countryDependent, setDisplayCountryDependent] = useState(false);
	const [regionCode, setRegionCode] = useState(null);
	const [universityId, setUniversityId] = useState(null);
	const [completedData, setCompletedData] = useState([]);
	const [completedTotalData, setCompletedTotalData] = useState(null);
	const [passeDate,setPasseDate] = useState("");
	const [checkReminderEmailDuration, setCheckReminderEmailDuration] = useState(2);
	const [checkReminderEmail, setCheckReminderEmail] = useState(true);
	const [checkEmailTestResult, setCheckEmailTestResult] = useState(true);
	const [allowDisplay,setAllowDisplay]=useState(false);
	const [disableSaveButtton,setDisableSaveButton]=useState(true);
	const [fromFlashcardResult] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.fromFlashcardResult :false);
	const [fromTutorialResult] = useState(props && props.history && props.history.location && props.history.location.state ? props.history.location.state.fromTutorialResult :false);
	const [statusValus, setstatusValus] = useState("");
	const [checkEmail,setCheckEmail]=useState(false);
	const [checkAlternateEmail,setCheckAlternateEmail]=useState(false);
	const [checkEmailCopy,setCheckEmailCopy]=useState(false);
	const [checkAlternateEmailCopy,setCheckAlternateEmailCopy]=useState(false);
	const [displayExtendValidity,setDisplayExtendValidity]=useState(false);
	const [extendedDate,setExtendedDate]=useState(null);
	const [lastUpdatedData,setLastUpdatedData]=useState([]);
	const [remark,setRemark]=useState(null);
	const [isHiddenDispay,setIsHiddenDisplay]=useState(false);
	const history = useHistory();
	const routetoNewWindow =() =>{
		window.open(constants.BaseUrl + `/json-info?${studentId}`);
	};
	const redirectToScorecard = (count,record,filtertype,retake,testType,retakeProgress) => {
		let param = {};
		param.userId = studentId;
		param.name = studentData?.stud_firstname +" "+studentData?.stud_lastname?.charAt(0);
		param.editStudent = true;
		param.count = count;
		param.is_active=studentData.is_active;
		let pathname = "";
		if(testType === "tutorial"){
			param.type  =1;
			pathname = "/tutorial";
		}else if(testType === "flashcard"){
			param.is_flashcard  =1;
			pathname = "/flashcard";
		}else{
			param.test = "test";
			pathname = "/test";
		}
		if(retakeProgress === "retake_completed"){
			param.progress = "Completed";
		}else if(retakeProgress === "retake_inprogress"){
			param.progress = "Inprogress";
		}else if(retakeProgress === "retake_paused"){
			param.progress = "Paused";
		}
		if(retake){
			param.retake = true;
			param.testType = record?.completed;
		}
		if(filtertype === "completed"){
			param.testType = record?.completed;
			param.progress = "Completed";
			param.retake = false;
		}
		else if(filtertype === "inprogress"){
			param.progress = "Inprogress";
			param.testType = record?.completed;
			param.retake = false;
		}
		else if(filtertype === "paused"){
			param.progress = "Paused";
			param.testType = record?.completed;
			param.retake = false;
		}
		else if(filtertype === "totalTest"){
			param.total = "total";
			param.retake = false;
			param.progress = "Completed";
		}
		history.push({
			pathname:pathname,
			state:param
		});
	};
	const column = [
		{
			title: <span>Book C{<span onClick={routetoNewWindow}>o</span>}de</span>,
			key: "book_code",
			dataIndex: "book_code",
		},
		{
			title: "Type",
			key: "type",
			dataIndex: "type",
		},
		{
			title: "Start Date",
			key: "start_date",
			dataIndex: "start_date",
			render: (text) => (
				<span>{text ? moment(text).format(constants.dateFormat) : "-"}</span>
			),
		},
		{
			title: <div className="justify-between">  
				Valid Until 
				<Space>
					<Button type="link" className="pr-0" onClick={()=>{ShowExtendModal();}}>Extend </Button>    
					{remark?<Tooltip title={<div
						dangerouslySetInnerHTML={{ __html: remark }}
					/>}><InfoCircleOutlined/></Tooltip>:""}
				</Space>
			</div>,
			key: "valid_till",
			dataIndex: "valid_till",
			render: (text) => (
				<span>{text? moment(text).format(constants.dateFormat): "-"}  </span>
			),
		}
	];
	const columnCompleted = [
		{
			title: "",
			key: "completed",
			dataIndex: "completed",
		},
		{
			title: "Tests",
			children: [
				{
					title: "Completed",
					key: "tests",
					dataIndex: "tests",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						const count = x?.split("/")?.[0];
						if(!text||count==0||count==="0"){
							return <span style={{ width: "55px" }}>{text?text:"-"}</span>;
						}else{
							return <span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(count,record,"completed");}}>{text?text:"-"}</a></span>;
						}
					},
				},
				{
					title: "In Progress",
					key: "inprogress",
					dataIndex: "inprogress",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						let countInProgress = x?.split("/")?.[0];
						if(!text||countInProgress==0||countInProgress==="0"){
							return <span style={{ width: "55px" }}>{text?countInProgress:"-"}</span>;
						}else{
							return <span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(countInProgress,record,"inprogress");}}>{text?countInProgress:"-"}</a></span>;
						}
					},
				},
				{
					title: "Paused",
					key: "paused",
					dataIndex: "paused",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						let countPaused = x?.split("/")?.[0];
						if(!text||countPaused==0||countPaused==="0"){
							return <span style={{ width: "55px" }}>{text?countPaused:"-"}</span>;
						}else{
							return <span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(countPaused,record,"paused");}}>{text?countPaused:"-"}</a></span>;
						}
					},
				},
			]
		},
		{
			title: "Retake",
			// key: "retake",
			// dataIndex: "retake",
			// className: 'td-right',
			// align: 'center',
			// render: (text,record) => (
			// 	<span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(record,null,"retake");}}>{text?text:"-"}</a></span>
			// ),
			children: [
				{
					title: "Completed",
					key: "tests",
					dataIndex: "retake",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						const count = x?.split("/")?.[0];
						if(!text||count==0||count==="0"){
							return <span style={{ width: "55px" }}>{text?text:"-"}</span>;
						}else{
							return <span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(count,record,null,"retake",null,"retake_completed");}}>{text?text:"-"}</a></span>;
						}
					},
				},
				{
					title: "In Progress",
					key: "inprogress",
					dataIndex: "retake_inprogress",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						let countInProgress = x?.split("/")?.[0];
						if(!text||countInProgress==0||countInProgress==="0"){
							return <span style={{ width: "55px" }}>{text?countInProgress:"-"}</span>;
						}else{
							return <span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(countInProgress,record,null,"retake",null,"retake_inprogress");}}>{text?countInProgress:"-"}</a></span>;
						}
					},
				},
				{
					title: "Paused",
					key: "paused",
					dataIndex: "retake_paused",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						let countPaused = x?.split("/")?.[0];
						if(!text||countPaused==0||countPaused==="0"){
							return <span style={{ width: "55px" }}>{text?countPaused:"-"}</span>;
						}else{
							return <span style={{ width: "55px" }}><a onClick ={()=>{redirectToScorecard(countPaused,record,null,"retake",null,"retake_paused");}}>{text?countPaused:"-"}</a></span>;
						}
					},
				},
			]
		},
		{
			title: "Tutorials",
			children: [
				{
					title: "Completed",
					key: "tutorials_complete",
					dataIndex: "tutorials_complete",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						const count = x?.split("/")?.[0];
						if(!text||count==0||count==="0"){
							return <span>{text?text:"-"}</span>;
						}else{
							return <span><a onClick ={()=>{redirectToScorecard(count,record,"completed",null,"tutorial");}}>{text?text:"-"}</a></span>;
						}
					},
				},
				{
					title: "In Progress",
					key: "tutorials_inprogress",
					dataIndex: "tutorials_inprogress",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						let countInProgress = x?.split("/")?.[0];
						if(!text||countInProgress==0||countInProgress==="0"){
							return <span>{text?countInProgress:"-"}</span>;
						}else{
							return <span><a onClick ={()=>{redirectToScorecard(countInProgress,record,"inprogress",null,"tutorial");}}>{text?countInProgress:"-"}</a></span>;
						}
					},
				},
			]
		},
		{
			title: "Flashcards",
			children: [
				{
					title: "Completed",
					key: "flashcards_completed",
					dataIndex: "flashcards_completed",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						const count = x?.split("/")?.[0];
						if(!text||count==0||count==="0"){
							return <span>{text?text:"-"}</span>;
						}else{
							return <span><a onClick ={()=>{redirectToScorecard(count,record,"completed",null,"flashcard");}}>{text?text:"-"}</a></span>;
						}
					},
				},
				{
					title: "Paused",
					key: "flashcards_inprogress",
					dataIndex: "flashcards_inprogress",
					className: 'td-right',
					align: 'center',
					render: (text,record) => {
						let x = text;
						let countPaused = x?.split("/")?.[0];
						if(!text||countPaused==0||countPaused==="0"){
							return <span>{text?countPaused:"-"}</span>;
						}else{
							return <span><a onClick ={()=>{redirectToScorecard(countPaused,record,"paused",null,"flashcard");}}>{text?countPaused:"-"}</a></span>;
						}
					},
				},
			]
		},
	];
	// //To return badge 
	// const getbadge = (badgeType) => {
	// 	switch (
	// 		badgeType
	// 	) {
	// 	case constants.Novoice.toLocaleLowerCase():
	// 		return (<div className="hexagon level1"><StarFilled className="score-icon"/>  </div>);
	// 	case constants.Achiever.toLocaleLowerCase():
	// 		return (<div className="hexagon level2"><Certificate className="score-icon"/> </div>);
	// 	case constants.Scholar.toLocaleLowerCase():
	// 		return (<div className="hexagon level3"><Graduation className="score-icon"/> </div>);
	// 	case constants.AllStar.toLocaleLowerCase():
	// 		return (<div className="hexagon level4"><WheatStar className="score-icon"/> </div>);
	// 	case constants.Champion.toLocaleLowerCase():
	// 		return (<div className="hexagon level5"><TrophyOutlined className="score-icon"/> </div>);
	// 	default:
	// 		<div className="hexagon level1"><StarFilled className="score-icon"/> </div>;
	// 	}
	// };
	const redirectStudentDisplay=(studentId)=>{
		localStorage.setItem("studentId", studentId);
		// localStorage.setItem("editRedirectLink","/promo-code-list");
		window.open(constants.BaseUrl+"/edit-student-display", '_blank');
	};
	//Executes on change of the Role field
	const onChangeRole = (value) => {
		if (value === 3) {
			setDisplayOccupation(true);
		} else {
			setDisplayOccupation(false);
		}
	};
	//To display Extend date modal
	const ShowExtendModal=()=>{
		setDisplayExtendValidity(true);
	};
	const handleValidTillOk = () => {
		saveExtendedvalidity();
		setExtendedDate(null);
		setDisplayExtendValidity(false);
	};
	const handleValidTillCancel = () => {
		setExtendedDate(null);
		setDisplayExtendValidity(false);
	};
	const onPanelChange = (value) => {
		setExtendedDate(moment(value._d).format(constants.dateFormat));
	};
	//Executes on change of Country field
	const onChangeCountry = (_value, option) => {
		if (option && option.key !== "US") {
			setDisplayCountryDependent(false);
			setDisplayOtherUniversity(false);
			form.setFieldsValue({ university: null });
			form.setFieldsValue({ otherUniversity: null });
			form.setFieldsValue({ state: null });
			setRegionCode(null);
		} else {
			setDisplayCountryDependent(true);//true
			form.setFieldsValue({ university: universityId ? universityId : null });
		}
	};
	//Function to set region id and to display other university field if other or NA is selected
	const onChangeUniversity = (value, option) => {
		setRegionCode(option.props.region);
		setUniversityId(value);
		form.setFieldsValue({ state: option.props.stateValue });
		if (option.props.children === "Other") {
			setDisplayOtherUniversity(true);
		} else {
			setDisplayOtherUniversity(false);
			form.setFieldsValue({ otherUniversity:null });
		}
	};
	//Function to check validation errors on click of register button
	const onFinishFailed = (values) => {
		if ((values && values.values && values.values.contactNo === null) ||
			(values && values.values && values.values.contactNo === undefined) ||
			(values && values.values && values.values.contactNo === "")) {
			const styles2 = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(styles2);
		}
	};
	//Function to check the contact number and set validation error using css
	const handleContactNo = (value) => {
		if (value === "" || value === null || value === undefined) {
			const styles1 = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(styles1);
		} else {
			setStyles(null);
		}
	};
	// Validates Occupation to check if it is same as role or if it is empty
	const handleValidateOccupation = (_rule, value, callback) => {
		try {
			if (value) {
				if (value.trim().toLowerCase() === "student" || value.trim().toLowerCase() === "professional" || value.trim().toLowerCase() === "faculty" || value.trim().toLowerCase() === "other"
					|| value.trim().toLowerCase() === "students" || value.trim().toLowerCase() === "professionals" || value.trim().toLowerCase() === "facultys" || value.trim().toLowerCase() === "others") {
					throw new Error(constants.OccupationRole);
				}
				if (value.trim() === "") {
					throw new Error(constants.OccupationRegistration);
				}
			} else {
				throw new Error(constants.OccupationRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidatePhone = (_rule, value, callback) => {
		try {
			if (value) {
				let usernameValidate = /^(?=.{5,})\S*$/;
				if (usernameValidate.test(value) === false) {
					const styles3 = { border: '1px solid rgba(255, 77, 79)', };
					setStyles(styles3);
					throw new Error(constants.ContactNoRegistration);
				} else {
					setStyles(null);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//To fetch the list of countries
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
			} else {
				setCountries([]);
			}
		});
	};
	//To fetch the list of Universities
	const fetchUniversities = () => {
		fetchApi(`/universities?country_code=US`, "get").then(
			(res) => {
				if (res && res.data) {
					Object.entries(res.data).forEach(([key, value]) => {
						displayOtherUniversityAlaska(key,value);
					});
					setUniversities(res.data);
				} else {
					setUniversities(null);
				}
			}
		);
	};
	//To send deliverable email
	const sendDeliverableEmail =(studentId)=>{
		if(checkEmail!==checkEmailCopy || checkAlternateEmail!==checkAlternateEmailCopy){
			if(checkEmail!==true || checkAlternateEmail!==true ){
				fetchApi(`/changed_deliverable_email/${studentId}`, "post").then(
					(res) => {
						if (res && res.code) {
							if (res.code === 200) {
								message.success(res.data);
							} else {
								message.error(res.data);
							}
						}
					}
				);
			}else{
				console.log("Failed");
			}
		}else{
			console.log("Failed");
		}
	};
	//To fetch the student data
	const fetchStudentInfo = () => {
		fetchAdminApi(`/student/${studentId}`, "get").then(
			(res) => {
				if (res && res.data) {
					setStudentData(res.data);
					setstatusValus(res?.data?.is_active);
					setStudentFormValues(res.data);
					setAllowDisplay(res.data.allow_display&&res.data.allow_display==="1"?true:false);
					setCheckEmail(res.data.allow_display&&res.data.is_del_p==="t"?true:false);
					setCheckAlternateEmail(res.data.allow_display&&res.data.is_del_a==="t"?true:false);
					setCheckEmailCopy(res.data.allow_display&&res.data.is_del_p==="t"?true:false);
					setCheckAlternateEmailCopy(res.data.allow_display&&res.data.is_del_a==="t"?true:false);
					if (res.data.email_results && res.data.email_results === "1") {
						setCheckEmailTestResult(true);
					} else {
						setCheckEmailTestResult(false);
					}
					if (res.data.email_frequency && res.data.email_frequency === "1") {
						setCheckReminderEmail(true);
						setCheckReminderEmailDuration(1);
					} else if (res.data.email_frequency && res.data.email_frequency === "2") {
						setCheckReminderEmail(true);
						setCheckReminderEmailDuration(2);
					} else if (res.data.email_frequency && res.data.email_frequency === "3") {
						setCheckReminderEmail(true);
						setCheckReminderEmailDuration(3);
					} else {
						setCheckReminderEmail(false);
						setCheckReminderEmailDuration(0);
					}
				} else {
					setStudentData([]);
				}
			}
		);
	};
	//Fetch last Updated
	const fetchStudentLastUpdateInfo = () => {
		fetchApi(`/last-updates/${studentId}`, "get").then(
			(res) => {
				if (res && res.data) {
					setLastUpdatedData(res.data);
				}
			}
		);
	};
	//On switch Changed
	const onSwitchChange = (checked) => {
		setIsTimerValue(checked);
	};
	//To save Form values
	const onFinalSave = (values) => {
		let payload = null;
		payload = {
			stud_id: studentId,
			is_active: values.status,
			is_timer: isTimerValue === true ? 1 : 0,
			stud_firstname: values.firstName.trim(),
			stud_lastname: values.lastName.trim(),
			login_username: studentData && studentData.login_username,
			stud_email: values.email,
			alternate_stud_email: values.alternateEmail,
			stud_contact_no: values.contactNo,
			occupation_type: values.role === "Not Specified" ? null : values.role,
			other_occupation: values.occupation ? values.occupation : null,
			country_id: values.country,
			// state_id:regionCode,
			// university_id:values.university?values.university:null,
			other_university: values.otherUniversity ? values.otherUniversity : null,
			allow_display:allowDisplay===true?"1":"0",
			is_del_p:checkEmail===true?"t":"f",
			is_del_a:checkAlternateEmail===true?"t":"f",
			// valid_till:subscriptionData[0].valid_till,
		};
		if (values.university) {
			if (isNaN(values.university)) {
				if (values.university.includes("Other_")) {
					payload.university_id = null;
					payload.state_id = regionCode;
				} else if (values.university.includes("NA")||values.university.includes("Other")) {
					payload.university_id = null;
					payload.state_id = regionCode;
				}
			} else {
				payload.university_id = values.university;
				payload.state_id = regionCode;
			}
		}
		if (checkEmailTestResult === true) {
			payload.email_results = 1;
		} else {
			payload.email_results = 0;
		}
		if(checkReminderEmail){
			if (checkReminderEmailDuration) {
				payload.email_frequency = checkReminderEmailDuration;
			}
		}else{
			payload.email_frequency=0;
		}
		saveEditedDetails(payload);
	};
	const saveEditedDetails=(payload)=>{
		fetchAdminApi(`/student/${studentId}`, "put", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(constants.SuccessUpdate);
						sendDeliverableEmail(studentId);
						fetchStudentInfo();
						fetchStudentLastUpdateInfo();
					} else {
						message.error(constants.FailedUpdate);
					}
				} else {
					message.error(constants.FailedUpdate);
				}
			}
		);
	};
	//Exten Validity
	const saveExtendedvalidity=()=>{
		let payload={};
		payload={
			valid_till:extendedDate,
			admin_name:getCookie(COOKIE.FirstName)
		};
		fetchAdminApi(`/extend-dates/${studentId}`, "put", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.data);
						fetchStudentInfo();
						fetchStudentLastUpdateInfo();
					} else {
						message.error(res.data);
					}
				}
			}
		);
	};
	//set university Information
	const setUniversityInfo=(studentInfo)=>{
		if (studentInfo.other_university && studentInfo.other_university === "NA") {
			setRegionCode("");
			form.setFieldsValue({ university: "NA" });
		} else if (studentInfo.other_university) {
			setRegionCode("");
			setDisplayOtherUniversity(true);
			form.setFieldsValue({ university: "Other" });
			form.setFieldsValue({ otherUniversity: studentInfo.other_university });
		}
	};
	//To set Form Values
	const setStudentFormValues = (studentInfo) => {
		let queryString = window.location.href;
		let isHidden=false;
		var hrefStringvalue = queryString.substring(queryString.lastIndexOf('/') + 1);
		if(hrefStringvalue==="edit-student-display"){
			isHidden=true;
		}
		form.setFieldsValue({
			status: studentInfo.is_active,
			firstName: studentInfo.is_active==="4"&&isHidden===false?"Stud - "+studentId:studentInfo.stud_firstname,
			email: studentInfo.is_active==="4"&&isHidden===false?"":studentInfo.stud_email,
			contactNo: studentInfo.is_active==="4"&&isHidden===false?"":studentInfo.stud_contact_no,
			role: studentInfo.occupation_type === null ?4:parseInt(studentInfo.occupation_type),
			country: studentInfo.country_id,
			lastName: studentInfo.is_active==="4"&&isHidden===false?"":studentInfo.stud_lastname,
			alternateEmail: studentInfo.is_active==="4"&&isHidden===false?"":studentInfo.alternate_stud_email,
			occupation: studentInfo.other_occupation,
			state: studentInfo.region_name,
			otherUniversity: studentInfo.other_university
		});
		if(studentInfo.is_active == "2" || studentInfo.is_active == "0"){
			setDisableSwith(true);
		}else{
			setDisableSwith(false);
		}
		if (studentInfo.university_id) {
			if (studentInfo.region_code) {
				form.setFieldsValue({ university: Number(studentInfo.university_id) });
				setRegionCode(Number(studentInfo.region_id));
			}
		} else {
			if (studentInfo.region_code) {
				setRegionCode(Number(studentInfo.region_id));
				setDisplayOtherUniversity(true);
				form.setFieldsValue({ university: "Other" });
				form.setFieldsValue({ otherUniversity: studentInfo.other_university });
			} else {
				setUniversityInfo(studentInfo);
			}
		}
		setCompletedData(studentInfo.completed);
		setCompletedTotalData(studentInfo?.total_results);
		setUniversityId(Number(studentInfo.university_id));
		setDisplayOccupation(Number(studentInfo.occupation_type) === 3 ? true : false);
		setDisplayOtherUniversity(true);
		setIsTimerValue(Number(studentInfo.is_timer) == 1 ? true : false);
		setSubscriptionData(studentInfo.history);
		setRemark(studentInfo?.history[0]?.remarks);
		setDisplayCountryDependent(studentInfo.country_code !== "US" ? false : true);
		setDisplayOtherUniversity(studentInfo.other_university !== null ? true : false);
	};
	// To validate alternate email
	// function validateAlternateEmail(altEmailDomainText,emailDomainText) {
	// 	if(altEmailDomainText&&emailDomainText){
	// 		if(emailDomainText!==altEmailDomainText){
	// 			return Promise.resolve();
	// 		}else{
	// 			return Promise.reject(
	// 				new Error(
	// 					constants.AltEmailDomain
	// 				)
	// 			);
	// 		}
	// 	}
	// 	else{
	// 		return Promise.resolve();
	// 	}
	// } 
	// To return breadcrumb
	function getBreadcrumbs(tittle1,tittle2) {
		if(tittle1&&tittle2){
			return(
				<>
					<Breadcrumb.Item><a onClick={() => history.push({ pathname: passeDate && passeDate.push2, })}>{ passeDate.title}</a></Breadcrumb.Item>
					<Breadcrumb.Item><a onClick={() => history.push({ pathname: passeDate && passeDate.push, })}>{ passeDate.title}</a></Breadcrumb.Item>
				</>
			);
		}else if(tittle1){
			return(
				<Breadcrumb.Item><a onClick={() => history.push({ pathname: passeDate && passeDate.push2, })}>{ passeDate.title}</a></Breadcrumb.Item>
			);
		}else if(tittle2){
			return(
				<Breadcrumb.Item><a onClick={() => history.push({ pathname: passeDate && passeDate.push, })}>{ passeDate.title}</a></Breadcrumb.Item>
			);
		}
	}
	//Executes on change of the duration i.e weekly, fortnightly or monthly
	const onChangeEmailDuration = (e) => {
		if (e.target.value === 1 || e.target.value === 2 || e.target.value === 3) {
			setCheckReminderEmail(true);
			setCheckReminderEmailDuration(e.target.value);
		}
	};
		//Executes on change of the Email test result field
	const onChangeEmailTestResult = (e) => {
		setCheckEmailTestResult(e.target.checked);
	};
		//Executes onChange of allow display name
	const onAllowDisplayChange=(e)=>{
		setAllowDisplay(e.target.checked);
	};
	const onEmailCheckChange=(e)=>{
		setCheckEmail(e.target.checked);
	};
	const onAlternateEmailChange=(e)=>{
		setCheckAlternateEmail(e.target.checked);
	};
		//Exectus on check of Remind email notification check box
	const onChangeRemindEmail = (e) => {
		if (e.target.checked === true) {
			setCheckReminderEmail(true);
			setCheckReminderEmailDuration(2);
		} else {
			setCheckReminderEmail(false);
			setCheckReminderEmailDuration(0);
		}
	};
	const feedbackPopoverContent = (
		<div>
			<b>1){" "}<Text type="danger">*</Text>{" "}{constants.FeedbackQ1}</b>
			{studentData?.student_feedback?.other_feedback !== null?
				<><Input className="w-50"  
					value={studentData?.student_feedback?.other_feedback}
				/><br/></>:
				<p className ="ml-4">{studentData?.student_feedback?.book_status}</p>
			}
			<b>2){" "}<Text type="danger">*</Text>{" "}{constants.FeedbackQ2}</b>
			<p className ="ml-4">{studentData?.student_feedback?.test_status}</p>
			<b>3){" "}{constants.FeedbackQ3}</b>
			<p className ="ml-4">{studentData?.student_feedback?.test_rating}</p>
			{studentData?.student_feedback?.Comments &&(
				<TextArea className="w-100" autoSize =  { { 
					minRows: 2, 
					maxRows: 6 
				}} placeholder="Comments..."  
				value={studentData?.student_feedback?.Comments}
				/>
			)}
		</div>
	);
	const fetchUrlParameters=()=>{
		let queryString = window.location.href;
		var hrefStringvalue = queryString.substring(queryString.lastIndexOf('/') + 1);
		let allowEdit=sessionStorage.getItem("showEditSaveButton");
		if(hrefStringvalue!=="view-student"&&allowEdit==="show"){
			setDisableSaveButton(false);
		}else{
			setDisableSaveButton(true);
		}
		if(hrefStringvalue==="edit-student-display"){
			setIsHiddenDisplay(true);
		}else{
			setIsHiddenDisplay(false);
		}
	};
	const resendActivationEmail = () => {
		if(studentData?.login_username){
			let payload=null;
			payload = { login_username: studentData?.login_username?studentData?.login_username:"" };
			fetchApi("/resend_email", "post", payload)
				.then((res) => {
					if (res && res.code && res.code === 200) {
						message.success(constants.NewSuccessResendMessage);
					}else{
						message.error(res.message);
					}
				});
		}
	}; 
	//copy text on button click
	const copyToClipboard = () => {
		const el = document.createElement('textarea');
		el.value = studentData?.activation_token;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};
	useEffect(() => {
		fetchUrlParameters();
		fetchStudentInfo();
		fetchStudentLastUpdateInfo();
		fetchUniversities();
		fetchCountries();
		if(props && props.history && props.history.location && props.history.location.state){
			if (props.history.location.state.studentId) {
				localStorage.setItem("studentId", props.history.location.state.studentId);
			}
			if (props.history.location.state.dashboard){
				setFronDashboard(props.history.location.state.dashboard);
				localStorage.setItem("dashboard", props.history.location.state.dashboard);
			}
			if (props.history.location.state.passedDate){
				setPasseDate(props.history.location.state.passedDate);
				localStorage.setItem("passedDate", JSON.stringify(props.history.location.state.passedDate));
			}else if(props.history.location.state.dashboard){
				setPasseDate("");
			}
		}else{
			setFronDashboard(localStorage.getItem("dashboard"));
			setPasseDate(JSON.parse(localStorage.getItem("passedDate")));
		}
		return()=>{
			sessionStorage.removeItem("showEditSaveButton");
		};
	}, [props]);
	const resetButton =(resetType,scorecardType = null)=> {
		if(statusValus === "1" && isHiddenDispay)return <a onClick={()=>handleReset(resetType,scorecardType)}>reset</a>;
	};
	const handleReset = (resetType,scorecardType) => {
		let payload = {
			student_id :studentId,
			type: resetType
		};
		if(scorecardType!==null && resetType ===4 ){
			payload.scorecard_type = scorecardType;
		}
		fetchAdminApi(`/delete_student_details`, "post", payload).then(
			(res) => {
				if (res && res.code) {
					if (res.code === 200) {
						message.success(res.message);
						fetchStudentInfo();
						fetchStudentLastUpdateInfo();
					} else {
						message.error(res.message);
					}
				}
			}
		);
	};
	return (
		<>
			<div className="div">
				<div className="p-3">
					{disableSaveButtton===true?null:
						<Breadcrumb>
							<Breadcrumb.Item><a onClick={() => {
								history.push({ pathname: "/admin-dashboard" });
							}}>Home</a></Breadcrumb.Item>
							<Breadcrumb.Item ><a onClick = {() => {
								history.push({ pathname :"/analysis-menu" });
							}}>Analysis</a></Breadcrumb.Item>
							{passeDate && (
								<>
									{getBreadcrumbs( passeDate.title2,passeDate.title)}		
									<Breadcrumb.Item><a onClick={() => history.push({
										pathname: "/registered-students",
										state: passeDate
									})}>Registered Students</a></Breadcrumb.Item>
								</>
							)}
							{fromTutorialResult && (
								<Breadcrumb.Item><a onClick ={() => history.push({ pathname: "/tutorial-result" })}>{constants.tutorialResults}</a></Breadcrumb.Item>
							)}
							{fromTestResult && (
								<Breadcrumb.Item><a onClick ={() => history.push({ pathname: "/test-results" })}>{constants.testResults}</a></Breadcrumb.Item>
							)}
							{fromFlashcardResult && (
								<Breadcrumb.Item><a onClick ={() => history.push({ pathname: "/flashcard-result" })}>{constants.flashcardResults}</a></Breadcrumb.Item>
							)}
							<Breadcrumb.Item>Edit Student User</Breadcrumb.Item>
						</Breadcrumb>
					}
				</div>
				<div>
					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<Card size="small" title={<span className="text-uppercase">EDIT STUDENT USER
								<span style={{ visibility:"hidden" }}>
									<b>{" "}Student Id:</b> <a onClick={()=>redirectStudentDisplay(studentId)}>{studentId}</a>
								</span>
							</span>}
							>
								<Form form={form} onFinish={onFinalSave} onFinishFailed={onFinishFailed} labelWrap>
									<Row gutter={24}>
										<Col span={8}>
											{studentData&&(
												<>
													<Form.Item className="text-center">
														{studentData.stud_firstname && studentData.is_active!=="4" &&isHiddenDispay===false?(
															<Avatar
																shape="round"
																size={110}
																icon={
																	(  studentData.stud_firstname.charAt(0)) +
														( studentData.stud_lastname.charAt(0))
																}
															/>
														):
															<Avatar
																shape="round"
																size={110}
																icon={
																	"#"
																}
															/>}
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label={constants.Username}
														name="userName"
													>
														<span /*className="text-uppercase"*/>{studentData.is_active==="4"&&isHiddenDispay===false?"":studentData.login_username}</span>
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label="Status"
														name="status"
													>	
														{/* <Radio.Group className ="p-2" onChange={(e)=>onchangeStatus(e)} > */}
														<Space direction = "vertical">
															<Radio value={"0"} checked = {statusValus === "0" ? true:false}>Inactive</Radio>
															<Radio value={"1"} checked = {statusValus === "1" ? true:false}>Active</Radio>
															<Space>
																<Radio value={"2"} checked = {statusValus === "2" ? true:false}>Pending</Radio>
																{statusValus === "2" && (
																	<>
																		<Tooltip title={constants.ResendActvationTT}><Button onClick={resendActivationEmail} size="small" type ="primary">{constants.ResendBtn}</Button></Tooltip>
																		<Tooltip title={constants.CopyResendActivationText}><Button onClick={copyToClipboard} type="link" icon={<InfoCircleOutlined/>}></Button></Tooltip>
																	</>
																)}
															</Space>
															<Radio value={"3"} checked = {statusValus === "3" ? true:false}>Closed</Radio>
															<Radio value={"4"} checked = {statusValus === "4" ? true:false}>Cleared</Radio>
														</Space>
														{/* </Radio.Group> */}
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label="Timer"
														name="Timer"
													>
														<Switch checkedChildren="Enabled" unCheckedChildren="Disabled" disabled = {disableSwith} checked={isTimerValue} onChange={onSwitchChange}/>
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label="Feedback Provided"
													>
														<Space>
															<h2 className="mb-0">{studentData.is_feedback_given === "1" ? <Popover overlayClassName="popover-ml-3" content={feedbackPopoverContent} title={<h3 style={{ margin: "2px" }}><b>{constants.FeedBackTooltipTittle + " " + moment(studentData?.student_feedback?.feedback_date).format(constants.dateFormat)}</b></h3>}>
																<CheckCircleTwoTone/>
															</Popover> : <CloseCircleTwoTone twoToneColor="#E11A06"/>}
															</h2>
															{resetButton(2)}
														</Space>
													</Form.Item>
													{/* {studentData.total_points==="0"?null: */}
													<div className="text-center mb-3">
														<Space>
															{/* <div>
																{getBadgeIcon(studentData.level,"","score-icon")}
																<p className="result ">{studentData.badge}</p>
															</div> */}
															<div className="pr-3 space-between">
																<div className="pr-3">
																	<p className = "mb-0 text-mute2">{constants.Levels[studentData?.level]}
																	</p>
																	<p className="mb-0 text-mute2">{studentData?.badge}</p>
																</div>
																{getBadgeIcon(studentData.level,"","score-icon")}
															</div>
															<div className="text-left">
																<Space direction="vertical">
																	{/* <b><a>Level { studentData.level}</a></b> */}
																	<b>Total Points Earned: <a onClick={()=>
																	{
																		if(studentData&&studentData.total_points&&studentData.total_points!=="0"){
																			window.open(constants.BaseUrl + `/student-total-points?${studentId}`);
																		}
																	}
																	}>{studentData.total_points}</a></b>
																	<Space><b>Streak: <a onClick={() => {
																		if (studentData && studentData.streak && studentData.streak !== "0") {
																			window.open(constants.BaseUrl + `/student-streak?${studentId}`);
																		}
																	}
																	}>{studentData?.streak}</a></b> {resetButton(1)}</Space>
																	<b>QOD Answered: <a onClick={()=>
																	{
																		if(studentData&&studentData.qod_count&&studentData.qod_count!=="0"&&studentData.qod_count!==0){
																			window.open(constants.BaseUrl + `/student-qod-answered?${studentId}`);
																		}
																	}///student-qod-answered
																	}>{studentData?.qod_count}</a></b>
																</Space>
															</div>
														</Space>
													</div>
													{/* } */}
												</>
											)}
										</Col>
										<Col span={8} className="form-item-mb-3">
											<Form.Item
												{...verticalLayout}
												label={constants.FirstName}
												name="firstName"
												rules={[
													{
														required: false,
														message: constants.FirstNameRegistration,
													},
													{
														// pattern: /^(?=.{2,})[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
														pattern: /^(?=.{2,})[^0-9]*$/,
														message:
															constants.FirstNameInvalid,
													},
												]}
											>
												<Input placeholder={constants.FirstName}/>
											</Form.Item>
											<Form.Item
												{...verticalLayout}
												name="email"
												label={constants.Email}
												rules={[
													{
														type: "email",
														message: constants.EmailNotValid,
													},
													{
														required: false,
														message: constants.EmailRegistration,
													},
												]}
											>
												<Input placeholder={constants.Email}/>
											</Form.Item>
											<Form.Item className="profile-checkbox text-uppercase">
												<Checkbox
													checked={checkEmail}
													onChange={onEmailCheckChange}
												>
													<Tooltip title={constants.DeliverableText}>{constants.DeliverableText}</Tooltip> 
												</Checkbox>
											</Form.Item>
											<Form.Item
												{...verticalLayout}
												label={constants.ContactNo}
												className="phone-w-100"
												name="contactNo"
												rules={[
													{
														required: false,
														message: constants.RequiredField,
													},
													{ validator: handleValidatePhone },
												]}
											>
												<PhoneInput country={"us"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567"/>
											</Form.Item>
											<Form.Item
												{...verticalLayout}
												label={constants.Role}
												name="role"
												rules={[
													{
														required: false,
														message: constants.RequiredField,
													},
												]}
											>
												<Select
													showSearch
													placeholder={constants.IamA}
													optionFilterProp="children"
													filterOption={(input, option) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
													onChange={onChangeRole}
												>
													<Option value={0}>Student</Option>
													<Option value={1}>Professional</Option>
													<Option value={2}>Faculty</Option>
													<Option value={3}>Other</Option>
													<Option value={4}>Unspecified</Option>
												</Select>
											</Form.Item>
											<Form.Item
												{...verticalLayout}
												label={constants.Country}
												name="country"
												rules={[
													{
														required: false,
														message: constants.CountryRegistration,
													},
												]}
											>
												<Select
													showSearch
													placeholder={constants.USA}
													filterOption={(input, option) =>
														option.props.children
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0 ||
														option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
													onChange={onChangeCountry}
												>
													{/* */}
													{listCountries &&
														listCountries.map((country) => (
															<Option key={country.country_code} value={country.country_id}>
																{country.country_name}
															</Option>
														))}
												</Select>
											</Form.Item>
											{countryDependent && (
												<Form.Item
													{...verticalLayout}
													name="university"
													label="University"
													rules={[
														{
															required: false,//countryDependent
															message: constants.UniversityRegistration,
														},
													]}
												>
													<Select
														showSearch
														placeholder={constants.University}
														optionFilterProp={"children"}
														onChange={onChangeUniversity}
													>
														{listUniversities &&
															Object.entries(listUniversities).map(([key, value]) => (<OptGroup label={key} key={key}>
																{value && value.universities && value.universities.length > 0 &&
																	value.universities.map((universe) => (
																		<Option
																			key={universe.university_id}
																			value={universe.university_id}
																			region={universe.region_id}
																			stateValue={universe.region_name}
																		>
																			{universe.university_name + (universe.city ? "," + universe.city : "")}
																		</Option>
																	)
																	)
																}
															</OptGroup>
															))}
													</Select>
												</Form.Item>
											)}
										</Col>
										<Col span={8} className="form-item-mb-3">
											<Form.Item
												{...verticalLayout}
												label={constants.LastName}
												name="lastName"
												rules={[
													{
														required: false,
														message: constants.LastNameRegistration,
													},
													{
														// pattern: /^[A-Za-z]+((\s)?((.)?([A-Za-z])+))*$/,
														pattern: /^[^0-9]*$/,
														message:
															constants.LastNameInvalid,
													},
												]}
											>
												<Input placeholder={constants.LastName}/>
											</Form.Item>
											<Form.Item
												{...verticalLayout}
												name="alternateEmail"
												// dependencies={['email']}
												label={constants.AlternateEmail}
												// tooltip={constants.AlternateEmailTip}
												rules={[
													{
														type: "email",
														message: constants.EmailNotValid,
													},
													{
														required: false,
														message: constants.AlternateEmailRegistration,
													},
													// ({ getFieldValue }) => ({
													// 	validator(_, value) {
													// 		let messageEmail="";
													// 		if (!value || getFieldValue("email") !== value) {
													// 			if(value&&getFieldValue("email")&&value!==getFieldValue("email")){
													// 				let altemailDomain=value;
													// 				let altEmailDomainText=altemailDomain.substring(altemailDomain.indexOf('@') + 1);
													// 				let emailText=getFieldValue("email");
													// 				let emailDomainText=emailText.substring(emailText.indexOf("@")+1);
													// 				if((altEmailDomainText&&emailDomainText)&&(emailDomainText===altEmailDomainText)){
													// 					messageEmail=constants.AltEmailDomain;
													// 				}else{
													// 					return Promise.resolve();
													// 				}
													// 			}else{
													// 				return Promise.resolve();
													// 			}
													// 		}else{
													// 			messageEmail=constants.AlternateEmailSameAsEmail;
													// 		}
													// 		return Promise.reject(
													// 			new Error(
													// 				messageEmail
													// 			)
													// 		);
													// 	},
													// }),
												]}
											>
												<Input placeholder={constants.AlternateEmail}/>
											</Form.Item>
											<Form.Item className="profile-checkbox text-uppercase" >
												<Checkbox
													checked={checkAlternateEmail}
													onChange={onAlternateEmailChange}
												>
													<Tooltip title={constants.DeliverableText}>{constants.DeliverableText}</Tooltip> 
												</Checkbox>
											</Form.Item>
											<Form.Item {...verticalLayout} className="visibility-hidden" label="Blank form item">
												<Input/>
											</Form.Item>
											{displayOccupation ? (
												<Form.Item
													{...verticalLayout}
													name="occupation"
													label={constants.Occupation}
													rules={[
														{
															required: displayOccupation,
															message: " ",
														},
														{ validator: handleValidateOccupation },
													]}
												>
													<Input placeholder={constants.Occupation}/>
												</Form.Item>
											) :
												<Form.Item {...verticalLayout} className="visibility-hidden" label="Blank form item">
													<Input/>
												</Form.Item>
											}
											{countryDependent && (
												<Form.Item
													{...verticalLayout}
													label="State"
													name="state"
												>
													<Input placeholder={"State"} readOnly/>
												</Form.Item>
											)}
											{(displayOtherUniversity && countryDependent) && (
												<Form.Item
													{...verticalLayout}
													name="otherUniversity"
													label="Other University"
												// rules={[
												// 	{
												// 		required: displayOtherUniversity,
												// 		// required: false,
												// 		message: constants.OtherUniversityRegistration,
												// 	},
												// ]}
												>
													<Input placeholder={constants.UniversityName} disabled={false}
														addonAfter={<PlusOutlined/>}
													/>
												</Form.Item>
											)}
										</Col>
									</Row>
									<Row gutter={24}>
										<Col span={8}>
											{studentData&&(
												<>
													<Form.Item
														{...horizontalLayout}
														label={<span>Last Login Date</span>}
													>
														<span>{studentData?.dt_last_login}</span>
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label={<span>Last test attempted</span>}
													>
														<Space>
															<span>{lastUpdatedData.filter((e) => e.type === "TEST") ? lastUpdatedData.filter((e) => e.type === "TEST")[0]?.dt_last_modified !== "NULL" ? lastUpdatedData.filter((e) => e.type === "TEST")[0]?.dt_last_modified : "-" : "-"}</span>
															{resetButton(4,"0")}
														</Space>
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label={<span>Last tutorial attempted</span>}
													>
														<Space>
															<span>{lastUpdatedData.filter((e)=>e.type==="TUTORIAL")?lastUpdatedData.filter((e)=>e.type==="TUTORIAL")[0]?.dt_last_modified!=="NULL"?lastUpdatedData.filter((e)=>e.type==="TUTORIAL")[0]?.dt_last_modified:"-":"-"}</span>
															{resetButton(4,"1")}
														</Space>
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label={<span>Last flashcard attempted</span>}
													>
														<Space>
															<span>{lastUpdatedData.filter((e)=>e.type==="FLASHCARD")?lastUpdatedData.filter((e)=>e.type==="FLASHCARD")[0]?.dt_last_modified!=="NULL"?lastUpdatedData.filter((e)=>e.type==="FLASHCARD")[0]?.dt_last_modified:"-":"-"}</span>
															{resetButton(3)}
														</Space>
													</Form.Item>
													<Form.Item
														{...horizontalLayout}
														label={<span >Forgot password</span>}
													>
														<span >{studentData.forgot_password_dt?moment(studentData.forgot_password_dt).format(constants.dateFormat):"-"}</span>
													</Form.Item>
												</>
											)}
										</Col>
										<Col span={16}>
											<Row className="profile-checkbox">
												<Col>
													<Form.Item>
														<Tooltip title={constants.ReminderEmailNotificationTooltip}>
															<Checkbox
																onChange={onChangeRemindEmail}
																checked={checkReminderEmail}
															>
																{constants.ReminderEmailNotification}
															</Checkbox>
														</Tooltip>
													</Form.Item>
												</Col>
												<Col>
													<Form.Item>
														<Radio.Group
															onChange={onChangeEmailDuration}
															value={checkReminderEmailDuration}
														>
															<Radio value={1}>Weekly</Radio>
															<Radio value={2}>Biweekly</Radio>
															<Radio value={3}>Monthly</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
											</Row>
											<Form.Item className="profile-checkbox">
												<Tooltip title={constants.EmailTestResultsTooltip}>
													<Checkbox
														checked={checkEmailTestResult}
														onChange={onChangeEmailTestResult}
													>
														{constants.EmailMeTestResult}
													</Checkbox>
												</Tooltip>
											</Form.Item>
											<Form.Item className="profile-checkbox">
												<Tooltip title={constants.MyfirstNameTooltip}>
													<Checkbox
														checked={allowDisplay}
														onChange={onAllowDisplayChange}
													>
														{constants.MyfirstName}
													</Checkbox>
												</Tooltip>
											</Form.Item>
										</Col>
									</Row>
									<div className="text-right">
										<Space>
											{disableSaveButtton===true?
												null
												:
												<><Form.Item>
													<Button disabled={ studentData?.is_active === "4" ? true : false} htmlType="submit" className="btn-submit">Save</Button>
												</Form.Item><Form.Item>
													<Button onClick={() => history.push({
														pathname: fromDashboard ? "/admin-dashboard" : "/registered-students",
														state: passeDate
													})}>Cancel</Button>
												</Form.Item></>
											}
											{/* <Form.Item>
												<Button>Reset Password</Button>
											</Form.Item> */}
										</Space>
									</div>
								</Form>
								<div style={{ visibility:"hidden" }}>
									<b>Student ID:</b> {studentId}
								</div>
								<Divider >History</Divider>
								<Table className="gap-table" columns={column} dataSource={subscriptionData} pagination={false}/>
								<Divider/>
								<Table bordered className="gap-table" columns={columnCompleted} dataSource={completedData} pagination={false}
									summary={() => (
										<Table.Summary fixed>
											<Table.Summary.Row>
												<Table.Summary.Cell index={0}>Total ({completedTotalData && completedTotalData.total})</Table.Summary.Cell>
												<Table.Summary.Cell index={1} align="center" className='td-right'>
													<span>
														{completedTotalData?.completed?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.completed?.split("/")?.[0],null,"totalTest");}}>{completedTotalData && completedTotalData.completed}</a>
															:
															<span>{completedTotalData && completedTotalData.completed}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={2} align="center" className='td-right'>
													<span>
														{completedTotalData?.inprogress?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.inprogress?.split("/")?.[0],null,"inprogress");}}>{completedTotalData?.inprogress?.split("/")?.[0]}</a>
															:
															<span>{completedTotalData?.inprogress?.split("/")?.[0]}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={3} align="center" className='td-right'>
													<span>
														{completedTotalData?.paused?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.paused?.split("/")?.[0],null,"paused");}}>{completedTotalData?.paused?.split("/")?.[0]}</a>
															:
															<span>{completedTotalData?.paused?.split("/")?.[0]}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={4} align="center" className='td-right'>
													<span>
														{completedTotalData?.retake?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.retake?.split("/")?.[0],null,null,"retake",null,"retake_completed");}}>{completedTotalData && completedTotalData.retake}
															</a>
															:
															<span>{completedTotalData && completedTotalData.retake}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={5} align="center" className='td-right'>
													<span>
														{completedTotalData?.retake_inprogress?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.retake_inprogress?.split("/")?.[0],null,null,"retake",null,"retake_inprogress");}}>{completedTotalData?.retake_inprogress?.split("/")?.[0]}
															</a>
															:<span>{completedTotalData?.retake_inprogress?.split("/")?.[0]}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={6} align="center" className='td-right'>
													<span>
														{completedTotalData?.retake_paused?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.retake_paused?.split("/")?.[0],null,null,"retake",null,"retake_paused");}}>{completedTotalData?.retake_paused?.split("/")?.[0]}
															</a>
															:
															<span>{completedTotalData?.retake_paused?.split("/")?.[0]}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={7} align="center" className='td-right'>
													<span>
														{completedTotalData?.tutorials_complete?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.tutorials_complete?.split("/")?.[0],null,"completed",null,"tutorial");}}>{completedTotalData && completedTotalData.tutorials_complete}
															</a>
															:
															<span>{completedTotalData && completedTotalData.tutorials_complete}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={8} align="center" className='td-right'>
													<span>
														{completedTotalData?.tutorials_inprogress?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.tutorials_inprogress?.split("/")?.[0],null,"inprogress",null,"tutorial");}}>{completedTotalData?.tutorials_inprogress?.split("/")?.[0]}
															</a>
															:
															<span>{completedTotalData?.tutorials_inprogress?.split("/")?.[0]}</span>}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={9} align="center" className='td-right'>
													<span>
														{completedTotalData?.flashcards_completed?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.flashcards_completed?.split("/")?.[0],null,"completed",null,"flashcard");}}>{completedTotalData && completedTotalData.flashcards_completed}
															</a>
															:
															<span>{completedTotalData && completedTotalData.flashcards_completed}</span>
														}
													</span>
												</Table.Summary.Cell>
												<Table.Summary.Cell index={10} align="center" className='td-right'>
													<span>
														{completedTotalData?.flashcards_inprogress?.split("/")?.[0]!=="0"?
															<a onClick ={()=>{redirectToScorecard(completedTotalData?.flashcards_inprogress?.split("/")?.[0],null,"paused",null,"flashcard");}}>{completedTotalData?.flashcards_inprogress?.split("/")?.[0]}
															</a>
															:
															<span>{completedTotalData?.flashcards_inprogress?.split("/")?.[0]}</span>
														}
													</span>
												</Table.Summary.Cell>
											</Table.Summary.Row>
										</Table.Summary>
									)}
								/>
								{disableSaveButtton===true?
									<div className="text-right pt-4 pr-3">
										<Form.Item>
											<Button icon={<CloseOutlined/>} onClick={() =>{ 			
												localStorage.removeItem("editRedirectLink");
												window.close();
											}} type="primary" danger>Close</Button>
										</Form.Item>
									</div>
									:null}
							</Card>
						</Col>
						<Modal width={400} title="Validity Extension" visible={displayExtendValidity} onOk={handleValidTillOk} onCancel={handleValidTillCancel}>
							<DatePicker 
								format={constants.dateFormat}
								onChange={onPanelChange}
								disabledDate={(current) => {
									let customDate = moment();
									return current && current < moment(customDate);
								}} 
								className="w-100"
							/>
						</Modal>
					</Row>
				</div>
			</div>
		</>
	);
};
export default EditStudent;
