/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter  } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { message, notification } from 'antd';
//Route Dictionary
import RouteManager from "./routes";
//Parent Styles
import "antd/dist/antd.css";
import "./App.css";
import constants from "./constants/constants";
import OldBrowserDetector from 'old-browser-detector';
import { COOKIE, createCookie, getCookie } from "./services/cookie";
import { PaginationProvider } from "./globalFunctions/PaginationContext";
const Detector = new OldBrowserDetector(
	{
		s: {
			d: 14,
			m: 14
		},
		c: 100,
		i: 11,
		f: 100,
		o: 85,
	},function(e) {
		let version = e.v.valueOf();
		let browserName = e.n;
		let borwserCompatibility = getCookie(COOKIE.BrowserVersion);
		if (!borwserCompatibility) {
			if (browserName === "c" && version < 100 ||
				browserName === "s" && version < 14 && e.isMobile === false ||
				browserName === "f" && version < 100 ||
				browserName === "i" && version < 11 ||
				browserName === "o" && version < 90) {
				openNotification();
			}
			createCookie(COOKIE.BrowserVersion, true, null, "/");
		}
	});
const openNotification = () => {
	const args = {
		message: 'Browser Compatibility',
		description:
		constants.BrowserVersionText,
		duration: 0,
		style :{ 
			top: 75,
			width: 400,
		},
	};
	notification.open(args);
};
Detector.detect();
const App = () => (
	<BrowserRouter basename={constants.BaseUrl}>
		<PaginationProvider>
			<RouteManager/>
		</PaginationProvider>
	</BrowserRouter>
);
ReactDOM.render(<App/>, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
