/*
File Name: ReviewIncorrect.js
Author: Akshay Lotlikar
Modified On: 12/03/2022
Description: Review Incorrect answers or All answers of a test.
API's used:
1) /student/start-test
2) /score_card/${scoreCardId}
3) /student/quit-test
*/
import React, { useState,useEffect } from "react";
import { Col, Row, Button, Card, Radio, Space,Input, Divider,Modal, message,  Alert,Tooltip,Badge,Form, Spin } from 'antd';
import { LeftOutlined, RightOutlined, DragOutlined, RollbackOutlined,SaveOutlined } from '@ant-design/icons';
import '../../../styles/test.css';
import constants from "../../../constants/constants";
import {
	sortableContainer,
	sortableElement,
} from "react-sortable-hoc";
import { fetchApi } from "../../../services/api";
import{ COOKIE,getCookie } from "../../../services/cookie";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory } from "react-router";
import Draggables from 'react-draggable';
import $ from "jquery";
import Icon from '@ant-design/icons';
import CustomIcons from "../../../assets/CustomIcons";
import { displayQuestionDetails, featuredImageUrl, getBadgeIcon } from "../../../globalFunctions/GlobalFunctions";
import { cloneDeep } from "lodash";
import Loader from "../../Common/Cred/Loader";
import moment from "moment";
const Comment = (props) => (<Icon component={CustomIcons.getsvg("Comment")} {...props}/>);
const { TextArea } = Input;
//Draggable elements
const SortableItem = sortableElement(({ value,indexPos }) => (
	<ul className="test-sortable">
		<li className="d-flex">
			<span className="seq-counter">{constants.OptionstoDisplay[indexPos]}</span><div id={indexPos} className='list-item' dangerouslySetInnerHTML={{ __html:value }}></div>
		</li>
	</ul>
));
//Drag area
const SortableContainer = sortableContainer(({ children }) => {
	return <div>{children}</div>;
});
const ReviewIncorrect = (props) => {
	const history = useHistory();
	//Columns to display the drag and drop type questions
	let columnsOfDragAndDrop = {
		[1]: { items: [] },
		[2]: { items: [] },
		[3]: { items: [] },
		[4]: { items: [] },
		[5]: { items: [] },
		[6]: { items: [] },
		[7]: { items: [] },
		[8]: { items: [] },
		[9]: { items: [] },
	};
	const [listQuestions,setQuestions]=useState([]);
	const [questionIndex,setQuestionIndex]=useState(0);
	const [sortitems, setSortItems] = useState([]);
	const [refresh,setRefresh]=useState(false);
	const [columns, setColumns] = useState(columnsOfDragAndDrop);
	const [nextAndPreviousLoading,setNextAndPreviousLoading]=useState(false);
	const [scoreCardId,setScoreCardId]=useState(null);
	const [resultData,setResultData]=useState(null);
	//sessionStorage
	const [categoryDetails,setCategoryDetails]=useState({});
	const [reviewTutorial,setReviewTutorial]=useState({});
	const [noteForm] = Form.useForm();
	const [disabled,setIsDisabled]=React.useState(true);
	const [notesVisible, setnotesVisible] = useState(false);
	const draggleRef = React.createRef();
	const [notelist,setNotesList]=useState([]);
	const [bounds,setBounds]=React.useState({
		left: 0,
		top: 0,
		bottom: 0,
		right: 0 
	});
	const showModal = () => {
		setnotesVisible(true);
		let itemTemp=listQuestions[questionIndex];
		if (itemTemp.notes!==undefined&&itemTemp.notes!==null){
			setNoteValue(itemTemp.notes);
		}
	};
	const hideModal = () => {
		noteForm.resetFields();
		setnotesVisible(false);
	};
	const [count, setCount] = useState(0);
	const zbminus = () => {
		if (count < 2) {
			setCount(count + 1);
			if (count === 0) {
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.add('decreaseFontsmall');
				document.body.classList.remove('decreaseFont');
			}
		}	
	};
	useEffect(() => {
	}, [count]);
	const zb = () => {
		if (count >=0 ) {
			setCount(count - 1);
			if (count === 2) {
				document.body.classList.remove('decreaseFontsmall');
				document.body.classList.add('decreaseFont');
			}
			if (count === 1) {
				document.body.classList.remove('decreaseFont');
			}
		}
	};
	//To set note form
	const setNoteValue=(valueText)=>{
		noteForm.setFieldsValue({ note:valueText });
	}; 
		//notes modal
	const onStart = (event, uiData) => {
		const { clientWidth, clientHeight } = window?.document?.documentElement;
		const targetRect = draggleRef?.current?.getBoundingClientRect();
		let boundsForNotesModal={
			left: -targetRect?.left + uiData?.x,
			right: clientWidth - (targetRect?.right - uiData?.x),
			top: -targetRect?.top + uiData?.y,
			bottom: clientHeight - (targetRect?.bottom - uiData?.y),
		};
		setBounds(boundsForNotesModal);
	};
	useEffect(() => {
		if(document.body.classList.contains('decreaseFont')){
			document.body.classList.remove('decreaseFont');
		}
		if(document.body.classList.contains('decreaseFontsmall')){
			document.body.classList.remove('decreaseFontsmall');
		}
		$(document).keydown(function(event){
			if(event.keyCode===123||event.ctrlKey&&event.shiftKey&&event.keyCode===73||event.ctrlKey&&event.keyCode===67){
				return false;
			}
		});
		document.addEventListener('copy',disableCopy);
		document.addEventListener('contextmenu',disableContextMenu);
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
			fetchFromLocalStorage();
		}else{
			if(props&&props.location&&props.location.state&&props.location.state.categoryDetails){
				sessionStorage.setItem("categoryDetails", JSON.stringify(props.location.state.categoryDetails));
				sessionStorage.removeItem("reviewTutorial");
				sessionStorage.removeItem("levelData");
				fetchStartTestData();
			}else if(props&&props.location&&props.location.state&&props.location.state.reviewTutorial){
				sessionStorage.setItem("reviewTutorial", JSON.stringify(props.location.state.reviewTutorial));
				sessionStorage.removeItem("categoryDetails");
				fetchStartTestData();
			}else{
				let queryString = window.location.href;
				var routeValue = queryString.substring(queryString.lastIndexOf('/') + 1);
				if(routeValue==="review-incorrect"){
					history.push("/my-tests");
				}else{
					history.push("/home");
				}
			}
		}
	}, []);
	useEffect(() => {
		return () => {
			$(document).off('keydown');
			document.removeEventListener('contextmenu',disableContextMenu);
			document.removeEventListener('copy',disableCopy);
			sessionStorage.removeItem("categoryDetails");
			sessionStorage.removeItem("listQuestions");
			sessionStorage.removeItem("questionIndex");
		};
	}, []);
	function disableCopy(e) {
		e.preventDefault();
	}
	function disableContextMenu(e) {
		e.preventDefault();
	}
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			listQuestions[questionIndex].selectedAnswer=sortitems;
			sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
		}
	}, [sortitems]);
	//end of arrange the sequence code
	const returnInitials=(key)=>{
		let tempKey=parseInt(key);
		switch (tempKey) {
		case 1:	
			return " (1st attempt)";
		case 2:	
			return " (2nd attempt)";
		}
	};
	//To fetch the Questions to answer the test of the category type.
	const fetchStartTestData=()=>{
		setCategoryDetails(JSON.parse(sessionStorage.getItem("categoryDetails")));
		setReviewTutorial(JSON.parse(sessionStorage.getItem("reviewTutorial")));
		setNextAndPreviousLoading(true);
		let categoryDetailsFromProps=props.location.state.categoryDetails;
		let reviewTutorialFromProps=props.location.state.reviewTutorial;
		let url=`/tutorials/test-data`;
		let userId=getCookie(COOKIE.UserId);
		if(categoryDetailsFromProps){
			if(categoryDetailsFromProps.scoreCardId){
				url+=`?scorecard_id=${categoryDetailsFromProps.scoreCardId}`;
			}
			if(categoryDetailsFromProps.ans_inc==="incorrect"){
				url+=`&type=ans_incorrect`;
			}
			url+=`&review=${categoryDetailsFromProps.review}`;
			if(categoryDetailsFromProps.categoryId){
				url+=`&cat_id=${categoryDetailsFromProps.categoryId}`;
			}
			if(categoryDetailsFromProps.topicId){
				url+=`&topic_id=${categoryDetailsFromProps.topicId}`;
			}
		}
		if(reviewTutorialFromProps){
			if(reviewTutorialFromProps.scoreCardId){
				url+=`?scorecard_id=${Number(reviewTutorialFromProps.scoreCardId)}`;
			}
			if(reviewTutorialFromProps.review==="tutorial"){
				url+=`&review=${reviewTutorialFromProps.review}`;
			}
			if(reviewTutorialFromProps.categoryId){
				url+=`&cat_id=${Number(reviewTutorialFromProps.categoryId)}`;
			}
			if(reviewTutorialFromProps.topicId){
				url+=`&topic_id=${Number(reviewTutorialFromProps.topicId)}`;
			}
			if(reviewTutorialFromProps.type==="custom"||reviewTutorialFromProps.type==="topic"||reviewTutorialFromProps.type==="category"){
				url+=`&type=ans_incorrect`;
			}
		}
		url+=`&stud_id=${userId}`;
		fetchApi(url, "get").then((res) => {
			setNextAndPreviousLoading(false);
			if (res && res.code && res.code === 200) {
				if(reviewTutorialFromProps){
					setResultData(res?.data?.level_data);
					sessionStorage.setItem("levelData",JSON.stringify(res?.data?.level_data));
				}
				sessionStorage.setItem("testType","review");
				let reviewType="";
				if(categoryDetailsFromProps&&categoryDetailsFromProps.review==="short_length"){
					reviewType="ans_incorrect";
				}else if(categoryDetailsFromProps&&categoryDetailsFromProps.review==="full_length"){
					reviewType="ans_incorrect";
				}else if(reviewTutorialFromProps&&reviewTutorialFromProps.type==="custom"||
				reviewTutorialFromProps&&reviewTutorialFromProps.type==="topic"||
				reviewTutorialFromProps&&reviewTutorialFromProps.type==="category"){
					reviewType="review";
				}
				if(res.data&&res.data[reviewType]&&res.data[reviewType].question_list&&res.data[reviewType].question_list.length>0){
					for (let i = 0; i < res.data[reviewType].question_list.length; i++) {
						res.data[reviewType].question_list[i].selectedAnswer=null;
						res.data[reviewType].question_list[i].ratings=res.data[reviewType].question_list[i].user_rating?res.data[reviewType].question_list[i].user_rating:null;
						let correct_option=[];
						let user_answer_converted=[];
						let correctOptionConverted=[];
						if(res.data[reviewType].question_list[i].q_type==="5"){							
							for (let j = 0; j < res.data[reviewType].question_list[i].user_ans.length; j++) {
								user_answer_converted.push(constants.OptionstoDisplay[res.data[reviewType].question_list[i].user_ans[j]]);
							}
							//Splitting the correct options to display in alphabets and also checking if user answer is correct or not
							let matchAns=true;
							for (let j = 0; j < res.data[reviewType].question_list[i].correct_option.length; j++) {
								// if(res.data[reviewType].question_list[i].correct_option[j].includes("/")){
								// 	correct_option=res.data[reviewType].question_list[i].correct_option[res.data[reviewType].question_list[i].correct_option.length-1];
								// }
								let splitted=res.data[reviewType].question_list[i].correct_option[j].split("");
								let convertedToAlpha=[];
								for (let k = 0; k < splitted.length; k++) {
									convertedToAlpha.push(constants.OptionstoDisplay[splitted[k]]);
								}
								correctOptionConverted.push(convertedToAlpha.toString().replaceAll(",",""));
							}
							for (let j = 0; j < res.data[reviewType].question_list[i].correct_option.length; j++) {
								let split=res.data[reviewType].question_list[i].correct_option[j].split("/");
								if(res.data[reviewType].question_list[i].user_ans&&res.data[reviewType].question_list[i].user_ans.length>0){
									if (typeof res.data[reviewType].question_list[i].user_ans[j]!== undefined) {
										if(!split.includes(res.data[reviewType].question_list[i].user_ans[j]&&res.data[reviewType].question_list[i].user_ans[j].toString())){
											matchAns=false;
											break;
										}
									}
								}else{
									matchAns=false;
								}
							}
							res.data[reviewType].question_list[i].matchAns = matchAns;
							if(res.data[reviewType].question_list[i].option_first){
								res.data[reviewType].question_list[i].option_one=res.data[reviewType].question_list[i].option_first.substring(res.data[reviewType].question_list[i].option_first.indexOf("|")+1);
							}
							if(res.data[reviewType].question_list[i].option_second){
								res.data[reviewType].question_list[i].option_two=res.data[reviewType].question_list[i].option_second.substring(res.data[reviewType].question_list[i].option_second.indexOf("|")+1); 
							}
							if(res.data[reviewType].question_list[i].option_third){
								res.data[reviewType].question_list[i].option_three=res.data[reviewType].question_list[i].option_third.substring(res.data[reviewType].question_list[i].option_third.indexOf("|")+1);
							}
							if(res.data[reviewType].question_list[i].option_fourth){
								res.data[reviewType].question_list[i].option_four=res.data[reviewType].question_list[i].option_fourth.substring(res.data[reviewType].question_list[i].option_fourth.indexOf("|")+1);
							}
							if(res.data[reviewType].question_list[i].option_fifth){
								res.data[reviewType].question_list[i].option_five=res.data[reviewType].question_list[i].option_fifth.substring(res.data[reviewType].question_list[i].option_fifth.indexOf("|")+1); 
							}
							if(res.data[reviewType].question_list[i].option_sixth){
								res.data[reviewType].question_list[i].option_six=res.data[reviewType].question_list[i].option_sixth.substring(res.data[reviewType].question_list[i].option_sixth.indexOf("|")+1); 
							}
							if(res.data[reviewType].question_list[i].option_seventh){
								res.data[reviewType].question_list[i].option_seven=res.data[reviewType].question_list[i].option_seventh.substring(res.data[reviewType].question_list[i].option_seventh.indexOf("|")+1); 
							}
							if(res.data[reviewType].question_list[i].option_eighth){
								res.data[reviewType].question_list[i].option_eight=res.data[reviewType].question_list[i].option_eighth.substring(res.data[reviewType].question_list[i].option_eighth.indexOf("|")+1);
							}
						}else{
							for (let j = 0; j < res.data[reviewType].question_list[i].user_ans.length; j++) {
								user_answer_converted.push(constants.OptionstoDisplay[res.data[reviewType].question_list[i].user_ans[j]]);
							}
							for (let j = 0; j < res.data[reviewType].question_list[i].correct_option.length; j++) {
								let converted=Number(res.data[reviewType].question_list[i].correct_option[j]);
								correct_option.push(converted);
								correctOptionConverted.push(constants.OptionstoDisplay[converted]);
							}
							res.data[reviewType].question_list[i].matchAns = (res.data[reviewType].question_list[i].user_ans.length == correct_option.length) && res.data[reviewType].question_list[i].user_ans.every(function(element, index) {
								return element === correct_option[index]; 
							});
						}
						if(correctOptionConverted[0].includes("/")){
							correctOptionConverted=[correctOptionConverted[correctOptionConverted.length-1]];
						}else{
							correctOptionConverted;
						}	
						res.data[reviewType].question_list[i].user_answer_converted=user_answer_converted;
						res.data[reviewType].question_list[i].correct_option=correct_option;
						res.data[reviewType].question_list[i].correct_option_converted=correctOptionConverted;
						//Condition to display the STRIKED options
						if(res.data[reviewType].question_list[i].strike_data&&res.data[reviewType].question_list[i].strike_data.length>0){
							let optionNumver={};
							if(res.data[reviewType].question_list[i].q_type==="5"){
								optionNumver=constants.OptionNumberDragAndDrop;
							}else{
								optionNumver=constants.OptionNumbers;
							}
							for (let l = 0; l < res.data[reviewType].question_list[i].strike_data.length; l++) {
								let op=optionNumver[res.data[reviewType].question_list[i].strike_data[l]];
								res.data[reviewType].question_list[i][op]=`<strike>${res.data[reviewType].question_list[i][op]}</strike>`;
							}
						}
						//Condition to display the HIGHLIGHTED text for options
						if(res.data[reviewType].question_list[i].highlight_data&&res.data[reviewType].question_list[i].highlight_data.length>0){
							let optionNumver={};
							if(res.data[reviewType].question_list[i].q_type==="5"){
								optionNumver=constants.OptionNumberDragAndDrop;
							}else{
								optionNumver=constants.OptionNumbers;
							}
							for (let l = 0; l < res.data[reviewType].question_list[i].highlight_data.length; l++) {
								let op=optionNumver[res.data[reviewType].question_list[i].highlight_data[l].opt];
								res.data[reviewType].question_list[i][op]=res.data[reviewType].question_list[i].highlight_data[l].data;
							}
						}
						//Storing user answer to another variable
						if(res.data[reviewType].question_list[i].user_ans&&res.data[reviewType].question_list[i].user_ans.length>0){
							if(res.data[reviewType].question_list[i].q_type==="5"){
								let optionNumver=constants.OptionNumberDragAndDrop;
								let selectedAnswer=[];
								for (let j = 0; j < res.data[reviewType].question_list[i].user_ans.length; j++){
									let ddd=optionNumver[res.data[reviewType].question_list[i].user_ans[j]];
									selectedAnswer.push({
										id:`${res.data[reviewType].question_list[i].user_ans[j]}`,
										content:`${res.data[reviewType].question_list[i][ddd]}`
									});
								}
								res.data[reviewType].question_list[i].selectedAnswer=selectedAnswer;
								res.data[reviewType].question_list[i].columns=checkAnswerOfDragAndDropQuestions(res.data[reviewType].question_list[i]);
								setRefresh(!refresh);
							}else if(res.data[reviewType].question_list[i].q_type==="4"){
								let optionNumver=constants.OptionNumbers;
								let selectedAnswer=[];
								for (let j = 0; j < res.data[reviewType].question_list[i].user_ans.length; j++){
									let ddd=optionNumver[res.data[reviewType].question_list[i].user_ans[j]];
									selectedAnswer.push({
										index:res.data[reviewType].question_list[i].user_ans[j],
										value:res.data[reviewType].question_list[i][ddd],
									});
								}
								res.data[reviewType].question_list[i].selectedAnswer=selectedAnswer;
							}else{
								res.data[reviewType].question_list[i].selectedAnswer=res.data[reviewType].question_list[i].user_ans;
							}
						}
					}
					sessionStorage.setItem("listQuestions", JSON.stringify(res.data[reviewType].question_list));
					sessionStorage.setItem("questionIndex",0);
					let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
					setQuestions(listQuestionsFromSession);
					if(listQuestionsFromSession.length>0){
						setScoreCardId(listQuestionsFromSession[0].scorecards.score_card_id);
					}
					checkQuestionType(listQuestionsFromSession);
				}
			} else {
				message.error(res.message);
				setResultData(null);
				history.push("/my-tests");
			}
		});
	};
	//Function to fetch data from local storage
	const fetchFromLocalStorage=()=>{
		let listQuestionsFromSession=JSON.parse(sessionStorage.getItem("listQuestions") || "[]");
		let questionIndexFromSession=sessionStorage.getItem("questionIndex");
		let categoryDetailsFromSession=JSON.parse(sessionStorage.getItem("categoryDetails")||"{}");
		let reviewTutorialFromSession=sessionStorage.getItem("reviewTutorial")?JSON.parse(sessionStorage.getItem("reviewTutorial")):null;
		let levelData=sessionStorage.getItem("levelData")?JSON.parse(sessionStorage.getItem("levelData")):null;
		// let levelData=JSON.parse(sessionStorage.getItem("levelData") || "{}");
		if(listQuestionsFromSession&&listQuestionsFromSession.length>0){
			setCategoryDetails(categoryDetailsFromSession);
			setReviewTutorial(reviewTutorialFromSession);
			setQuestions(listQuestionsFromSession);
			if(levelData){
				setResultData(levelData);
			}
			if(listQuestionsFromSession.length>0){
				setScoreCardId(listQuestionsFromSession[0].scorecards.score_card_id);
			}
			setQuestionIndex(Number(questionIndexFromSession));
			checkQuestionType(listQuestionsFromSession);
		}else{
			if(categoryDetailsFromSession){
				returnToScore();
			}else{
				returnToScore("TUTOR");
			}
		}
	};
	//Function to check answer of drag and drop type questions.
	const checkAnswerOfDragAndDropQuestions=(question)=>{
		if(question&&question.question_id){	
			let optionNumver={
				1:"option_one",
				2:"option_two",
				3:"option_three",
				4:"option_four",
				5:"option_five",
				6:"option_six",
				7:"option_seven",
				8:"option_eight",
			};
			var totalOptions  = [1, 2, 3, 4, 5, 6, 7, 8];
			let selectedColumn=cloneDeep(constants.columnsOfDragAndDrop);
			if(totalOptions&&totalOptions.length>0){	
				let optionsRemaining=[];
				for (let index = 0; index < totalOptions.length; index++) {
					let dd=optionNumver[totalOptions[index]];
					if(question[dd]){
						optionsRemaining.push({
							id:`${totalOptions[index]}`,
							content:`${question[dd]}`
						});
					}
				}
				selectedColumn[1].items=optionsRemaining;
			}
			for (let index = 0; index < question.user_ans.length; index++) {
				if(question.option_first&&question.option_first.includes("~")&&selectedColumn[2].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[2].items=[obj];
				}
				else if(question.option_second&&question.option_second.includes("~")&&selectedColumn[3].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[3].items=[obj];
				}
				else if(question.option_third&&question.option_third.includes("~")&&selectedColumn[4].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[4].items=[obj];			
				}
				else if(question.option_fourth&&question.option_fourth.includes("~")&&selectedColumn[5].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[5].items=[obj];
				}
				else if(question.option_fifth&&question.option_fifth.includes("~")&&selectedColumn[6].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[6].items=[obj];
				}
				else if(question.option_sixth&&question.option_sixth.includes("~")&&selectedColumn[7].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[7].items=[obj];
				}
				else if(question.option_seventh&&question.option_seventh.includes("~")&&selectedColumn[8].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[8].items=[obj];
				}
				else if(question.option_eighth&&question.option_eighth.includes("~")&&selectedColumn[9].items.length<1){
					let obj={
						id:`${question.user_ans[index]}`,
						content:`${constants.OptionstoDisplay[question.user_ans[index]]}`
					};
					selectedColumn[9].items=[obj];
				}
			}
			return selectedColumn;
		}
	};
	//Function to check if question type is arrange sequence or drag and drop to display options on page load
	const checkQuestionType=(listOfQuestions)=>{
		let questionIndexFromSession=Number(sessionStorage.getItem("questionIndex"));
		if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="4"){
			let sortItems=[];
			if(listOfQuestions[questionIndexFromSession].selectedAnswer&&listOfQuestions[questionIndexFromSession].selectedAnswer.length>0){
				sortItems=listOfQuestions[questionIndexFromSession].selectedAnswer;
			}else{
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
					sortItems.push({
						index:1,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
					sortItems.push({
						index:2,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
					sortItems.push({
						index:3,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
					sortItems.push({
						index:4,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
					sortItems.push({
						index:5,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
					sortItems.push({
						index:6,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
					sortItems.push({
						index:7,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh 
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
					sortItems.push({
						index:8,
						value:listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth 
					});
				}
			}
			setSortItems(sortItems);
		}else if(listOfQuestions&&listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].q_type==="5"){
			if(listOfQuestions[questionIndexFromSession].selectedAnswer&&listOfQuestions[questionIndexFromSession].selectedAnswer.length>0){
				setColumns(listOfQuestions[questionIndexFromSession].columns);
			}else{
				let Items=[];
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_first){
					Items.push({
						id:`${1}`,
						content:`${listOfQuestions[questionIndexFromSession].option_one }`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_second){
					Items.push({
						id:`${2}`,
						content:`${listOfQuestions[questionIndexFromSession].option_two }`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_third){
					Items.push({
						id:`${3}`,
						content:`${listOfQuestions[questionIndexFromSession].option_three }`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fourth){
					Items.push({
						id:`${4}`,
						content:`${listOfQuestions[questionIndexFromSession].option_four }`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_fifth){
					Items.push({
						id:`${5}`,
						content:`${listOfQuestions[questionIndexFromSession].option_five}`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_sixth){
					Items.push({
						id:`${6}`,
						content:`${listOfQuestions[questionIndexFromSession].option_six}`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_seventh){
					Items.push({
						id:`${7}`,
						content:`${listOfQuestions[questionIndexFromSession].option_seven }`
					});
				}
				if(listOfQuestions.length>0&&listOfQuestions[questionIndexFromSession].option_eighth){
					Items.push({
						id:`${8}`,
						content:`${listOfQuestions[questionIndexFromSession].option_eight}`
					});
				}
				columns[1].items=Items;
				setColumns(columns);
				setRefresh(!refresh);
			}
		}
	};
	//Executes on click of Next button.
	const handleNextQuestion=(type)=>{
		if(listQuestions&&listQuestions.length>0){
			let payload = {};
			payload.scorecard_id=Number(listQuestions[questionIndex].scorecards.score_card_id);
			payload.question_id=listQuestions[questionIndex].question_id;
			if(listQuestions[questionIndex].ratings){
				if(!listQuestions[questionIndex].user_rating){
					payload.test_rating_data={
						question_id:Number(listQuestions[questionIndex].question_id),
						rating:listQuestions[questionIndex].ratings
					};
				}
			}
			setNextAndPreviousLoading(true);
			fetchApi(`/tutorials/test-data`, "put", payload)
				.then((res) => {
					setNextAndPreviousLoading(false);
					if (res && res.code && res.code === 200) {
						setColumns(constants.columnsOfDragAndDrop);
						setNextAndPreviousLoading(false);
						if(type==="NEXT"){
							sessionStorage.setItem("questionIndex",questionIndex+1);
							setQuestionIndex(questionIndex+1);
						}else if(type==="TUTOR"){
							returnToScore("TUTOR");
						}else{
							returnToScore();
						}
					} else {
						setNextAndPreviousLoading(false);
						message.error("Failed");
					}
				});
		}
	};
	//Executes on click of Previous button.
	const handlePreviousQuestion=()=>{
		setNextAndPreviousLoading(true);
		setColumns(constants.columnsOfDragAndDrop);
		sessionStorage.setItem("questionIndex",questionIndex-1);
		setQuestionIndex(questionIndex-1);
		setNextAndPreviousLoading(false);
	};
	//Function to redirect back to result page
	const returnToScore=(type)=>{
		if(type&&type==="TUTOR"){
			if(reviewTutorial&&reviewTutorial.type==="custom"){
				history.push("/select-custom-tutorial");
			}else if(reviewTutorial&&reviewTutorial.type==="topic"){
				history.push("/select-topic-tutorial");
			}else if(reviewTutorial&&reviewTutorial.type==="category"){
				history.push("/select-category-tutorial");
			}
		}else{
			let isTopic=sessionStorage.getItem("resultIsTopic");
			let scoreCardIdFromSession=sessionStorage.getItem("resultScorecardId");
			let categoryType=sessionStorage.getItem("resultTestCategory");
			let fullLengthScoreId=sessionStorage.getItem("fullLengthTestScorecadId");
			if(fullLengthScoreId){
				history.push({
					pathname: "/full-result-page",
					state: { scoreCardId:fullLengthScoreId }
				});
			}else{
				if(isTopic==="true"){
					history.push({
						pathname: "/result-page",
						state: { 
							scoreCardId:scoreCardIdFromSession,
							resultTypeFull:false,
							testCategory:null,
							isTopic:true,
						}
					});
				}else{
					if(categoryType==="MIXED"){
						history.push({
							pathname: "/result-page",
							state: { 
								scoreCardId:scoreCardIdFromSession,
								resultTypeFull:false,
								testCategory:categoryType,
								isTopic:false,
							}
						});
					}else{
						history.push({
							pathname: "/result-page",
							state: { 
								scoreCardId:scoreCardIdFromSession,
								resultTypeFull:true,
								testCategory:categoryType,
								isTopic:false,
							}
						});
					}			
				}
			}
		}
	};
	//Executes on state change of questionIndex state.
	useEffect(() => {
		if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="4"){
			let sortItems=[];
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				sortItems=listQuestions[questionIndex].selectedAnswer;
			}else{
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					sortItems.push({
						index:1,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					sortItems.push({
						index:2,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					sortItems.push({
						index:3,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					sortItems.push({
						index:4,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					sortItems.push({
						index:5,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					sortItems.push({
						index:6,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					sortItems.push({
						index:7,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh 
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					sortItems.push({
						index:8,
						value:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth 
					});
				}
			}
			setSortItems(sortItems);
		}else if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].q_type==="5"){
			if(listQuestions[questionIndex].selectedAnswer&&listQuestions[questionIndex].selectedAnswer.length>0){
				setColumns(listQuestions[questionIndex].columns);
			}else{
				let Items=[];
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first){
					Items.push({
						id:`${1}`,
						content:`${listQuestions[questionIndex].option_one }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second){
					Items.push({
						id:`${2}`,
						content:`${listQuestions[questionIndex].option_two }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third){
					Items.push({
						id:`${3}`,
						content:`${listQuestions[questionIndex].option_three }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth){
					Items.push({
						id:`${4}`,
						content:`${listQuestions[questionIndex].option_four }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth){
					Items.push({
						id:`${5}`,
						content:`${listQuestions[questionIndex].option_five}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth){
					Items.push({
						id:`${6}`,
						content:`${listQuestions[questionIndex].option_six}`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh){
					Items.push({
						id:`${7}`,
						content:`${listQuestions[questionIndex].option_seven }`
					});
				}
				if(listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth){
					Items.push({
						id:`${8}`,
						content:`${listQuestions[questionIndex].option_eight}`
					});
				}
				let columnsObject={
					[1]: { items: [] },
					[2]: { items: [] },
					[3]: { items: [] },
					[4]: { items: [] },
					[5]: { items: [] },
					[6]: { items: [] },
					[7]: { items: [] },
					[8]: { items: [] },
					[9]: { items: [] },
				};
				columnsObject[1].items=Items;
				setColumns(columnsObject);
				setRefresh(!refresh);
			}
		}
	}, [questionIndex]);
	//drag and drop
	// a CSS styling function to help us with reordering the result
	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		userSelect: "none",
		width: "calc(50% - 12px)",
		minWidth: '300px',
		marginRight: "12px",
		marginBottom: "15px",
		display: "inline-flex",
		alignItems: "center",
		flexWrap: "wrap",
		// change background colour if dragging
		background: isDragging ? "lightgreen" : "white",
		border: "1px solid lightgrey",
		borderRadius: "30px",
		position: "relative",
		overflow: "hidden",
		// styles we need to apply on draggables
		...draggableStyle,
	});
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightgrey" : "white",
		padding: "grid 0",
		width: "100%",
		marginRight: "-12px",
	});
	//end of drag and drop code
	const handleRatings=e=>{
		if(e.target.value){
			if(listQuestions&&listQuestions.length>0){
				listQuestions[questionIndex].ratings=e.target.value;
				sessionStorage.setItem("listQuestions", JSON.stringify(listQuestions));
				setQuestions(listQuestions);
				if(listQuestions.length>0){
					setScoreCardId(listQuestions[0].scorecards.score_card_id);
				}
				setRefresh(!refresh);
			}
		}
	};
	const singleSelect = (
		<Card className="testSection">
			{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?
				(<span>
					<h3>Case Study</h3>
					<hr/>
					<p onCopy={()=>{return false;}} className="test-question" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?listQuestions[questionIndex].case_study_content:"" }}></p>	
					<br/>
				</span>)
				:""} 
			<div className='d-flex mb-3'>
				<p className="test-question"  dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<Radio.Group className="test-options w-100 options-counter">
				<Space direction="vertical" className="w-100">
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?
						<Radio value={1} 
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===1?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:1===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}
						>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?
						<Radio value={2}
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===2?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner  review correct-ans"
										:"options options-inner review incorrect-ans"
									:2===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?
						<Radio value={3}
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===3?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:3===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?
						<Radio value={4}	
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===4?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:4===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?
						<Radio value={5}	
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===5?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:5===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?
						<Radio value={6}	
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===6?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:6===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?
						<Radio value={7}	
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===7?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:7===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></div>
						</Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?
						<Radio value={8}	
							className={listQuestions&&
							listQuestions.length>0&&
							listQuestions[questionIndex].user_ans?
								listQuestions[questionIndex].user_ans[0]===8?
									listQuestions[questionIndex].user_ans[0]===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review incorrect-ans"
									:8===listQuestions[questionIndex].correct_option[0]?
										"options options-inner review correct-ans"
										:"options options-inner review"
								:"options options-inner review"}>
							<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></div>
						</Radio>:""}
				</Space>
			</Radio.Group>
		</Card>
	);
	const multipleChoice = (
		<Card className="testSection">
			{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?
				(<span>
					<h3>Case Study</h3>
					<hr/>
					<p onCopy={()=>{return false;}} className="test-question" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?listQuestions[questionIndex].case_study_content:"" }}></p>	
					<br/>
				</span>)
				:""} 
			<div className='d-flex mb-3'>
				<p className="test-question"  dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<Radio.Group className="test-options w-100 review-counter">
				<Space direction="vertical" className="w-100">
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_first?<Radio value={1} className="options options-inner review" ><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_second?<Radio value={2} className="options options-inner review"><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_third?<Radio value={3} className="options options-inner review"><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fourth?<Radio value={4} className="options options-inner review"><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_fifth?<Radio value={5} className="options options-inner review" ><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_sixth?<Radio value={6} className="options options-inner review"><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_seventh?<Radio value={7} className="options options-inner review"><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh }}></div></Radio>:""}
					{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].option_eighth?<Radio value={8} className="options options-inner review"><div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth }}></div></Radio>:""}
				</Space>
			</Radio.Group>
		</Card>
	);
	const arrangeSequence = (
		<Card className="testSection">
			{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?
				(<span>
					<h3>Case Study</h3>
					<hr/>
					<p onCopy={()=>{return false;}} className="test-question" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?listQuestions[questionIndex].case_study_content:"" }}></p>	
					<br/>
				</span>)
				:""} 
			<div className='d-flex mb-3'>
				<p className="test-question"  dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
				<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
			</div>			
			<div>
				<p>* Reorder the correct sequence.</p>
				<SortableContainer>
					<ul className="test-sortable">
						{sortitems.map((item,index) => (
							<SortableItem disabled={true} key={item.index} index={index} value={item.value} indexPos={item.index}/>
						))}
					</ul>
				</SortableContainer>
			</div>
		</Card>
	);
	{/* Component used to display the options draggable component for drag and drop type questions */}
	const draggableComponent=(id)=>{
		return(					
			<div style={{ margin: 8 }}>
				<Droppable droppableId={id}>
					{(provided, snapshot) => {
						return (
							<div
								{...provided.droppableProps}
								className="droppable"
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{columns&&columns[id]&&columns[id].items&&columns[id].items.length>0&&columns[id].items.map((item, index) => {
									return (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
											isDragDisabled={true}
										>
											{(providedProp) => {
												return (
													<div
														ref={providedProp.innerRef}
														{...providedProp.draggableProps}
														{...providedProp.dragHandleProps}
													// style={getItemStyle(
													// 	snapshot.isDragging,
													// 	provided.draggableProps.style
													// )}
													>
														<div className="dnd-item" dangerouslySetInnerHTML={{ __html:item.content }}></div>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</div>
		);
	};
	{/* drag and drop */}
	const dragAndDrop=(
		<Col span={24} className="p-0">
			<Card className="testSection">
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?
					(<span>
						<h3>Case Study</h3>
						<hr/>
						<p onCopy={()=>{return false;}} className="test-question" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].case_study_content?listQuestions[questionIndex].case_study_content:"" }}></p>	
						<br/>
					</span>)
					:""} 
				<DragDropContext>
					<div className='d-flex d-flex-wrap mb-3 flex-direction-col'>
						<p className="test-question" dangerouslySetInnerHTML={{ __html:listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].question_text?listQuestions[questionIndex].question_text:"" }}></p>
						{listQuestions&&listQuestions.length>0&&
				<span className="test-question drag-question">
					<Space wrap>
						{listQuestions[questionIndex].option_first?
							listQuestions[questionIndex].option_first.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("~")) }}></p>{draggableComponent(2)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_first.indexOf("|")) }}></p>)
							:""
						}
						{listQuestions[questionIndex].option_second?
							listQuestions[questionIndex].option_second.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("~")) }}></p>{draggableComponent(3)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_second.substring(0,listQuestions[questionIndex].option_second.indexOf("|")) }}></p>)
							:""					
						}
						{listQuestions[questionIndex].option_third?
							listQuestions[questionIndex].option_third.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("~")) }}></p>{draggableComponent(4)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_third.substring(0,listQuestions[questionIndex].option_third.indexOf("|")) }}></p>)
							:""					
						}
						{listQuestions[questionIndex].option_fourth?
							listQuestions[questionIndex].option_fourth.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("~")) }}></p>{draggableComponent(5)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fourth.substring(0,listQuestions[questionIndex].option_fourth.indexOf("|")) }}></p>)
							:""					
						}
						{listQuestions[questionIndex].option_fifth?
							listQuestions[questionIndex].option_fifth.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("~")) }}></p>{draggableComponent(6)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_fifth.substring(0,listQuestions[questionIndex].option_fifth.indexOf("|")) }}></p>)
							:""					
						}
						{listQuestions[questionIndex].option_sixth?
							listQuestions[questionIndex].option_sixth.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("~")) }}></p>{draggableComponent(7)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_sixth.substring(0,listQuestions[questionIndex].option_sixth.indexOf("|")) }}></p>)
							:""					
						}
						{listQuestions[questionIndex].option_seventh?
							listQuestions[questionIndex].option_seventh.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("~")) }}></p>{draggableComponent(8)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_seventh.substring(0,listQuestions[questionIndex].option_seventh.indexOf("|")) }}></p>)
							:""					
						}
						{listQuestions[questionIndex].option_eighth?
							listQuestions[questionIndex].option_eighth.includes("~")?
								<><p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_first.substring(0,listQuestions[questionIndex].option_eighth.indexOf("~")) }}></p>{draggableComponent(9)}</>:
								(<p className="dnd-item" dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].option_eighth.substring(0,listQuestions[questionIndex].option_eighth.indexOf("|")) }}></p>)
							:""					
						}
					</Space>
				</span>
						}
						<span className="ml-3">{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].featured_img_data&&(featuredImageUrl(listQuestions[questionIndex].featured_img_data))}</span>
					</div>
					<Droppable droppableId={"1"}>
						{(provided, snapshot) => {
							return (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{columns&&columns[1]&&columns[1].items&&columns[1].items.length>0&&columns[1].items.map((item, index) => {
										return (
											<Draggable
												key={item.id}
												draggableId={item.id}
												index={index}
												isDragDisabled={true}
												className=""
											>
												{(providedProp, snapshots) => {
													return (
														<div
															ref={providedProp.innerRef}
															{...providedProp.draggableProps}
															{...providedProp.dragHandleProps}
															style={getItemStyle(
																snapshots.isDragging,
																providedProp.draggableProps.style
															)}
															className=""
														>
															<DragOutlined className="test-dnd-icon"/>
															<span className="dnd-counter">{constants.OptionstoDisplay[item&&item.id&&item.id]}</span>
															<div className="drag-option d-inlineblock" dangerouslySetInnerHTML={{ __html:item.content }}></div>
														</div>
													);
												}}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							);
						}}
					</Droppable>
				</DragDropContext>
			</Card>
		</Col>
	);
		//function to create a note
	const createNote=(values)=>{
		if(values.note!==undefined&&values.note!==null){
			let tempValueNote=values.note.trim();
			let tempNote=tempValueNote.replace( /^\s*[\r\n]/gm, "\n" );
			let tempNoteID=listQuestions[questionIndex].note_id;
			let indexTemp=notelist.findIndex((item)=>item.question_id===listQuestions[questionIndex].question_id);
			if(tempNote!==""&&listQuestions[questionIndex].note_id===null){
				listQuestions[questionIndex].notes=tempNote;
				let tempObj={
					note:tempNote,
					question_id:listQuestions[questionIndex].question_id
				};
				if(indexTemp===-1){
					notelist.push(tempObj);
				}else{
					notelist[indexTemp].note=tempNote;
				}
				saveNotes();
			}else{
				if(indexTemp!==-1&&tempNote===""){
					notelist.splice(indexTemp, 1);
				}
				listQuestions[questionIndex].notes=null;
			}
			if(tempNote!==""&&listQuestions[questionIndex].note_id!==null){
				listQuestions[questionIndex].notes=tempNote;
				let userId = getCookie(COOKIE.UserId);
				let payload=null;
				payload={
					stud_id:userId,
					notes:tempNote,
					score_card_id:scoreCardId,
					question_id:listQuestions[questionIndex].question_id
				};
				fetchApi(`/student/notes/${tempNoteID}`,"put",payload).then((res) => {
					if (res && res.code===200) {
						// message.success(res.message);
					} else {
						message.error(res.message);
					}
				});
			}
			if(tempNote===""&&listQuestions[questionIndex].note_id!==null){
				fetchApi(`/student/notes/${tempNoteID}`, "delete").then((res) => {
					if (res && res.code===200) {
						//	message.success(res.message);
						listQuestions[questionIndex].notes=null;
						listQuestions[questionIndex].note_id=null;
					} else {
						message.error(res.message);
					}
				});
			}
			hideModal();
		}
	};
	const saveNotes=()=>{
		if(notelist.length>0){
			let userId = getCookie(COOKIE.UserId);
			let payload=null;
			payload={
				stud_id:userId,
				notes:notelist,
				score_card_id:scoreCardId,
			};
			fetchApi(`/student/notes`,"post",payload).then((res) => {
				if (res && res.code===200) {
					// message.success(res.message);
					listQuestions[questionIndex].note_id=res.notes_id;
					setNotesList([]);
				} else {
					message.error(res.message);
				}
			});
		}
	};
	return (
		<div className="p-4 full-length-test-container review-incorrect">
			<Row  gutter={[{
				xs: 0,
				sm: 0,
				md: 24,
				lg: 24 
			}, {
				xs: 24,
				sm: 24,
				md: 24,
				lg: 24 
			}]}>
				<Col xs={24} sm={24} md={24} lg={24}>
					{listQuestions&&listQuestions.length>0&&categoryDetails&&!resultData?(<h3 className="mainHeading">{constants.ReviewHeader}{" - "}{categoryDetails.testTitle?categoryDetails.testTitle:""}{categoryDetails.attempt?returnInitials(categoryDetails.attempt):""}</h3>):""}
					{listQuestions&&listQuestions.length>0&&reviewTutorial&&resultData?(<h3 className="mainHeading">{reviewTutorial.type?reviewTutorial.type.toLowerCase()==="custom"?constants.ReviewHeaderCustomTutorial: reviewTutorial.type.toLowerCase()==="topic"?constants.ReviewHeaderTopicTutorial:constants.ReviewHeaderCategoryTutorial:constants.ReviewHeaderTutorial}</h3>):null}
					<Divider className="mb-0"/>
					<div className="justify-between mt-3">
						{listQuestions&&listQuestions.length>0&&categoryDetails?
							categoryDetails.totalTimeTaken?
								(<p className="correct-answer mb-0">You answered {categoryDetails&&categoryDetails.totalCorrectAnswers&&categoryDetails.totalTestQuestions?categoryDetails.totalTestQuestions-categoryDetails.totalCorrectAnswers :0}/{categoryDetails.totalTestQuestions?categoryDetails.totalTestQuestions:""} questions incorrectly in this test on {categoryDetails.testTakenDate?categoryDetails.testTakenDate:""}.</p>):
								null
							:""
						}
						{/* {categoryDetails?
							categoryDetails.testTakenDate?
								(<p className={"correct-answer text-right mb-0"}>{categoryDetails.testTakenDate?categoryDetails.testTakenDate:""}{" "}</p>)
								:""
							:""} */}
					</div>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Row gutter={[{
						xs: 8,
						sm: 8,
						md: 0 
					}, 0]}>
						<Col sm={8} className="test-question-details">
							{listQuestions&&listQuestions.length>0&&<span>Question{" : "}{questionIndex+1} { "of" } {listQuestions.length}</span>}
						</Col>
						{listQuestions && listQuestions.length > 0 &&
							reviewTutorial && reviewTutorial.type &&
							reviewTutorial.type.toLowerCase() === "category" ||
							listQuestions && listQuestions.length > 0 &&
							reviewTutorial && reviewTutorial.type &&
							reviewTutorial.type.toLowerCase() === "custom" ||
							listQuestions && listQuestions.length > 0 &&
							categoryDetails && categoryDetails.review &&
							categoryDetails.review ?
							<Col sm={8} className="test-question-details text-center "><span className="cat-title">Category</span>
								{" "}: {listQuestions[questionIndex].category_abbreviation ? listQuestions[questionIndex].category_abbreviation : ""}</Col> :
							listQuestions && listQuestions.length > 0 &&
								reviewTutorial && reviewTutorial.type &&
								reviewTutorial.type.toLowerCase() === "topic" ?
								<Col sm={8} className="test-question-details text-center "><span className="cat-title">Topic</span>{" "}: {listQuestions[questionIndex].topic_title ? listQuestions[questionIndex].topic_title : ""}</Col> :
								null}
						<Col sm={8} className="test-question-details text-right ">
							{listQuestions &&
								listQuestions.length > 0 ?
								reviewTutorial ?
									reviewTutorial.type?
										reviewTutorial.type.toLowerCase()==="custom" ?
											<><span className="cat-title">Topic</span>{" "} : {listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].topic_abbreviation?listQuestions[questionIndex].topic_title:""}</>
											:null
										:null
									:<><span className="cat-title">Topic</span>{" "} : {listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].topic_abbreviation?listQuestions[questionIndex].topic_title:""}</>
								:""}
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Row>
						<Col sm={8}></Col>
						<Col sm={8}></Col>
						<Col sm={8} className="text-right">
							{categoryDetails&&categoryDetails.review==="short_length"|| reviewTutorial&&reviewTutorial.review==="tutorial"?
								<Space size={'middle'} className="text-right test-icon-container">
									{
										listQuestions.length>0
											?listQuestions[questionIndex].notes!==null
						&&listQuestions[questionIndex].notes!==undefined
						&&listQuestions[questionIndex].notes!==""?
												<Tooltip title="Notes"><Button type="text" icon={<Badge dot color={"blue"}><Comment/></Badge>} onClick={showModal}></Button></Tooltip>
												:
												<Tooltip title="Notes"><Button type="text" icon={<Comment/>} onClick={showModal}></Button></Tooltip>
											:null	}
									<Tooltip title="Decrease font"><Button onClick={()=>{zbminus();}} className={count>=2? "zoom-btn iconDisable": "zoom-btn"}>A-</Button></Tooltip>
									<Tooltip title="Increase font" placement="topRight"><Button onClick={()=>{zb();}} className={count<=0? "zoom-btn iconDisable": "zoom-btn"}>A+</Button></Tooltip>
								</Space>
								:null}
						</Col>
					</Row>
				</Col>
				<Col span={24} className="review-answers">
					<Spin className="flex-middle h-100vh" spinning={nextAndPreviousLoading} size="large" indicator={<Loader/>}>
						<>
							{listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="1"?singleSelect:
								listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="2"?multipleChoice:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="4"?arrangeSequence:listQuestions&&
						listQuestions.length>0&&
						listQuestions[questionIndex].q_type&&
						listQuestions[questionIndex].q_type==="5"?dragAndDrop:""
							}
						</>
					</Spin>
				</Col>
				{listQuestions&&
					listQuestions.length>0?
					listQuestions[questionIndex].selectedAnswer&&
					listQuestions[questionIndex].selectedAnswer.length>0?
						listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type!=="1"?(
							<Col span={8}>
								{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].matchAns!==null&&
						listQuestions[questionIndex].matchAns===true?
									<Alert message="Correct" type="success" showIcon/>: <Alert message="Incorrect" type="error" showIcon/>}
							</Col>):""
						:listQuestions[questionIndex].is_marked_for_later===1&&<Col span={8}><Alert message="Incorrect" type="error" showIcon/></Col>:""
				}
				{listQuestions&&
				listQuestions.length>0&&
				listQuestions[questionIndex].user_ans.length===0&&listQuestions[questionIndex].is_marked_for_later===0?
					<Col span={8}><Alert message="Not Answered" type="error" showIcon/></Col>
					:null}
				{listQuestions&&
					listQuestions.length>0&&listQuestions[questionIndex].q_type&&listQuestions[questionIndex].q_type!=="1"?(
						<Col span={24}>
							<Card bordered={false} className="ans-display">
								<Row gutter={[0, 12]}>
									<Col span={24}><b>Your Answer: </b> {
										listQuestions&&
										listQuestions.length>0&&
										listQuestions[questionIndex].user_answer_converted&&
										listQuestions[questionIndex].user_answer_converted.length>0?
											listQuestions[questionIndex].user_answer_converted.join(", "):""}</Col>
									<br/>
									<Col span={24}><b>Correct Answer: </b> {listQuestions&&
										listQuestions.length>0&&
										listQuestions[questionIndex].correct_option_converted&&
										listQuestions[questionIndex].correct_option_converted.length>0?
										listQuestions[questionIndex].correct_option_converted[0].includes("/")?listQuestions[questionIndex].correct_option_converted[0].replaceAll("/",", "):
											listQuestions[questionIndex].correct_option_converted.join(", ")
										:listQuestions&&
										listQuestions.length>0&&
										listQuestions[questionIndex].correct_option_converted&&listQuestions[questionIndex].correct_option_converted}</Col>
								</Row>
							</Card>
						</Col>)
					:""
				}
				{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].matchAns!==null&&
				listQuestions[questionIndex].matchAns===false?
					<Col span={24}>
						<Card title="Explanation" bordered={false} className="explanation testSection">
							{listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].explanation?<div dangerouslySetInnerHTML={{ __html:listQuestions[questionIndex].explanation }}></div>:""}
						</Card>
					</Col>
					:""
				}
				{listQuestions&&listQuestions.length>0&&!listQuestions[questionIndex].user_rating&&(<Col span={24} className="">
					<span>The question was : </span>
					<Radio.Group 
						value={listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].ratings} 
						buttonStyle="solid"
						onChange={handleRatings}>
						<Radio.Button value={1}>Easy</Radio.Button>
						<Radio.Button value={2}>Medium</Radio.Button>
						<Radio.Button value={3}>Difficult</Radio.Button>
					</Radio.Group>
				</Col>)}
				<Col xs={24} sm={24} md={16} lg={16} className="test-btn-container">
					<Space size={'middle'} wrap>
						{questionIndex>0&&
							<Button className="btnDb min-width-100" onClick={()=>{handlePreviousQuestion();}} disabled={nextAndPreviousLoading} ><LeftOutlined/>{constants.Previous}</Button>}
						{listQuestions&&questionIndex < listQuestions.length - 1&&
							(<Button className="btnDb min-width-100" onClick={()=>{handleNextQuestion("NEXT");}} disabled={nextAndPreviousLoading}>{constants.Next}<RightOutlined/></Button>
							)}
						{listQuestions&&listQuestions.length>0?categoryDetails&&!resultData?(
							<Button className="btnDb min-width-100" onClick={()=>{handleNextQuestion();}}><RollbackOutlined/>{constants.ReturnToResult}</Button>
						):(<Button className="btnDb min-width-100" onClick={()=>{handleNextQuestion("TUTOR");}}><RollbackOutlined/>{constants.ReturnToTutorials}</Button>):null}
						{
							reviewTutorial&&resultData && listQuestions&&listQuestions.length>0&&listQuestions[questionIndex].scorecards ?(
								<p className="mb-1">You answered {listQuestions.filter(x => x.matchAns === true).length}/{listQuestions.length} questions correctly on {moment(listQuestions[questionIndex].scorecards.date_attempted,"DD/MM/YYYY").format(constants.dateFormat)}.</p>
							): null
						}
					</Space>
				</Col>
				<Col xs={24} sm={24} md={8} lg={8} className = "justify-end-middle">
					{
						reviewTutorial&&resultData && (
							<>
								<div className="pr-3 space-between">
									<div className="pr-3">
										<p className='mb-0'>{constants.Levels[resultData&&
								resultData.level&&
								resultData.level.current_level&&
								resultData.level.current_level.level]}
										</p>
										<span>{resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.designation}</span>
									</div>
									{getBadgeIcon(resultData&&resultData.level&&resultData.level.current_level&&resultData.level.current_level.level,"","score-icon")}
								</div>
								<div className="fs-20">
									{resultData && resultData.earned_points &&(
										<p className="mb-1">You earned <span className="blue-2">{Number(resultData.earned_points) > 1 || Number(resultData.earned_points) === 0  ? `${resultData && resultData.earned_points} points`:`${resultData && resultData.earned_points} point`} </span> on this attempt </p>
									)}	
									<p className="mb-1">Total points earned : <span className="blue-2">{resultData.total_points}</span> </p>
									{!resultData.is_next_level?
										<>
											{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=1?
												resultData&&resultData.level&&resultData.level.next_level&&resultData.level.current_level.level>=5?
													<p className="mb-1">{constants.Level6Message}</p>:
													<p className="mb-1"><span className="blue-2">{resultData.points_required_level}</span> {constants.ReachLevelText}{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.level+", "+resultData.level.next_level.designation}</p>:
												<p className="mb-1"><span>{constants.ReachLevel0}{", "}</span>{resultData&&resultData.level&&resultData.level.next_level&&resultData.level.next_level.designation}</p>}
										</>
										:<p className="mb-1">Congratulations! You reached <span className="blue-2">Level {resultData&&resultData.level&&resultData.level.current_level.level}</span></p>
									}
								</div>
							</>)
					}
				</Col>
			</Row>
			<Row>
				{listQuestions&&listQuestions.length>0?
					displayQuestionDetails(listQuestions,questionIndex)
					:""}
			</Row>
			<Modal
				title=
					{
						<div
							style={{
								width: '100%',
								cursor: 'move', 
							}}
							onMouseOver={() => {
								if (disabled) {
									setIsDisabled(false);
								}
							}}
							onMouseOut={() => {
								setIsDisabled(true);
							}}onFocus={() => {}}
							onBlur={() => {}}
						>
								Notes
						</div>
					}
				visible={notesVisible}
				onOk={hideModal}
				onCancel={hideModal}
				className="notes-modal"
				footer={null						}
				modalRender={modal => (
					<Draggables
						disabled={disabled}
						bounds={bounds}
						onStart={(event, uiData) => onStart(event, uiData)}
					>
						<div ref={draggleRef}>{modal}</div>
					</Draggables>
				)}
			>
				<Form form={noteForm} onFinish={createNote}>
					<Form.Item name="note">
						<TextArea 
							rows={4} 
							placeholder=" Write a note"
							className="p-0"
							onPaste={(e)=>{
								e.preventDefault();
								return false;
							}} onCopy={(e)=>{
								e.preventDefault();
								return false;
							}}
						/>
					</Form.Item>
					<Form.Item className="mb-0">
						<Button className="btn-submit min-width-100" htmlType="submit"><SaveOutlined/> Save</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};
export default ReviewIncorrect;
